import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import Text from "../layout/Text";
import Spacer from "../layout/Spacer";
import {GatsbyImage} from "gatsby-plugin-image";
import useIntersect from "../../hooks/useIntersect";
import Conditional from "../layout/Conditional";
// import {randomInt} from "../../lib/utils";

const Comp = styled.div`
  width: ${({theme}) => theme.cards.standardSize};
  height: ${({theme}) => theme.cards.standardSize};
  background: ${p => p.isFirefox ? "rgba(248, 249, 250, 1)" : "rgba(248, 249, 250, 0.1)"};
  backdrop-filter: blur(50px);
  border-radius: ${({theme}) => theme.radii.standard};
  cursor: grab;
  box-shadow: -5px 3px 10px ${({theme}) => theme.colors.dropShadow}, inset -2px 2px 5px ${({theme}) => theme.colors.insetLight};
    //transform: rotateZ(${Math.random() * (5 - -5) + -5}deg);
  user-select: none;
  overflow: hidden!important;
  display: flex;
  color: ${p => p.drag ? p.theme.colors.background : p.theme.colors.accent};
  transition: all 0.4s;

  .image {
    transition: all 0.4s;
    opacity: ${p => p.drag ? 1 : 0};
  }

  :active {
    cursor: grabbing;
  }

  @media (max-width: ${({theme}) => theme.breakPoints.m}) {
    width: 30vw;
    height: 30vw;
  }

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    width: ${({theme}) => theme.cards.smallestSize};
    height: ${({theme}) => theme.cards.smallestSize};
  }
`

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute !important;
  top: 0;
  left: 0;
  user-select: none;
  border-radius: ${({theme}) => theme.radii.standard}!important;
`

const TopText = styled.div`
`

const CardContent = styled.div`
  z-index: 200;
  width: 100%;
  height: 100%;
  padding: ${({theme}) => theme.cards.padding};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${p => p.drag ? "linear-gradient(180deg, rgba(24, 91, 224, 0) 0%, rgba(24, 91, 224, 0) 20%, rgba(24, 91, 224, 0) 70%, rgba(24, 91, 224, 1) 90%, rgba(24, 91, 224, 1) 100%)" : null};
  transition: all 0.4s;
`

const TextItem = styled(Text)`
  filter: ${p => p.drag ? "drop-shadow(0px 0px 10px rgba(24, 91, 224, 0.5))" : null};
  overflow-wrap: break-word;
`

const DragCard = (props) => {
    const {field, name, title, image, index, dragItem, setHover, targetRef} = props;
    const [drag, setDrag] = useState(false)
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    useEffect(() => {
        setDrag(index === dragItem)
    }, [dragItem, index])

    const objRef = useRef()
    const intersect = useIntersect(objRef, targetRef)

    useEffect(() => {
        setHover(intersect ? index : -1)
    }, [intersect, setHover, index])

    return (
        <Comp
            ref={objRef}
            unselectable="on"
            drag={drag ? 1 : 0}
            isFirefox={isFirefox ? 1 : 0}
            // onSelectStart={() => false}
        >

            <CardContent drag={drag ? 1 : 0}>
                <TopText>
                    <Conditional desktop>
                        <TextItem drag={drag ? 1 : 0} s="cardsField">
                            {field}
                        </TextItem>
                        <Spacer s="smaller"/>
                    </Conditional>
                    <TextItem drag={drag ? 1 : 0} s="cardsName">
                        <Conditional desktop>
                            {name[0]} {name[1]}
                        </Conditional>
                        <Conditional mobile>
                            {name[0]} {name[1]}
                        </Conditional>
                    </TextItem>
                </TopText>
                <TextItem drag={drag ? 1 : 0} s="cardsTitle">
                    {title}
                </TextItem>
            </CardContent>

            {image && image.image && image.image.asset ?
                <Image drag={drag ? 1 : 0} draggable={false} alt={image.text}
                       image={image.image.asset.gatsbyImageData} className="image"/>
                : null}
        </Comp>
    );
};

export default DragCard;