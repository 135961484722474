import React from 'react';
import styled from "styled-components";
import Layout from "../components/layout/Layout";
import PageTitle from "../components/UI/PageTitle";
import {graphql} from "gatsby";
import localize from "../components/localization/localize";
import LetterSection from "../components/04_graduates/LetterSection";
import Spacer from "../components/layout/Spacer";
import GlobalWidth from "../components/layout/GlobalWidth";
import ScrollButton from "../components/UI/ScrollButton";

// const Comp = styled.div`
//
// `

const Container = styled(GlobalWidth)`
  display: flex;
  flex-wrap: wrap;
  //flex-direction: column;
  //max-height: 800vh;
  //background-color: palevioletred;
`

const Graduates = ({data, ...props}) => {
    const pageData = data.sanityPage.content
    const titleData = pageData.filter(item => item.identification.section === "title" && item.identification.id === "title")[0].content
    const scrollTop = pageData.filter(item => item.identification.section === "scrolltop" && item.identification.id === "button")[0].content

    const graduates = data.allSanityStudent.nodes

    const alphabetical = () => {
        if (graduates.length === 0) {
            return [];
        }
        return Object.values(
            graduates.reduce((acc, graduate) => {
                let firstLetter = graduate.lastName[0].toLocaleUpperCase();
                if (!acc[firstLetter]) {
                    acc[firstLetter] = {title: firstLetter, names: [graduate]};
                } else {
                    acc[firstLetter].names.push(graduate);
                }
                return acc;
            }, {})
        );
    }

    const sections = alphabetical().map((item, index) => {
        return (
            <LetterSection key={index} data={item}/>
        )
    })

    return (
        <Layout lang={props.pageContext.locale}  footerVideo title={data.sanityPage.title}>
            <PageTitle>
                {titleData}
            </PageTitle>
            <Spacer s="large"/>

            <Container>
                {sections}
            </Container>

            <ScrollButton text={scrollTop}/>
        </Layout>
    );
};

export default localize(Graduates);

export const query = graphql`
    {
        sanityPage(title: {en: {eq: "Graduates"}}) {
            title {
                _type
                en
                fr
            }
            content {
                ... on SanityContentString {
                    content {
                        _type
                        en
                        fr
                    }
                    identification {
                        id
                        section
                    }
                }
                ... on SanityContentText {
                    content {
                        _type
                        en
                        fr
                    }
                    identification {
                        id
                        section
                    }
                }
            }
        }
        allSanityStudent(sort: {fields: lastName}) {
            nodes {
                lastName
                firstName
                diploma {
                    name {
                        _type
                        en
                        fr
                    }
                    field {
                        name {
                            _type
                            en
                            fr
                        }
                    }
                }
                option {
                    name {
                        _type
                        en
                        fr
                    }
                }
                _id
            }
        }
    }
`