import React from 'react';
import styled from "styled-components";
import {GatsbyImage} from "gatsby-plugin-image";

const Comp = styled.div`
  filter: ${({theme}) => theme.filters.cardDropShadow};
  border-radius: ${({theme}) => theme.radii.standard};
  // background-color: ${({theme}) => theme.colors.background};
  overflow: hidden;
  
  position: relative;
  width: 15vw;
  max-width: ${({theme}) => theme.cards.standardSize};
  max-height: ${({theme}) => theme.cards.standardSize};
  min-width: ${({theme}) => theme.cards.smallerSize};
  min-height: ${({theme}) => theme.cards.smallerSize};
  padding-top: 100%;

  @media (max-width: ${({theme}) => theme.breakPoints.s}) { // prev m
    width: ${({theme}) => theme.cards.smallSize};
    height: ${({theme}) => theme.cards.smallSize};
  }
`

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute!important;
  top: 0;
  left: 0;
  //z-index: 50;
`

const Gradient = styled.div`
  position: absolute;
  background: linear-gradient(180deg, rgba(24, 91, 224, 0) 0%, rgba(24, 91, 224, 1) 90%, rgba(24, 91, 224, 1) 100%);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: ${p => p.hover ? 1 : 0};
  transition: all 0.4s;
`

const ProjectCard = (props) => {
    return (
        <Comp onMouseEnter={() => props.setHover(true)} onMouseLeave={() => props.setHover(false)}>
            <Gradient className='gradient' hover={props.hover}/>
            {props.data.coverImage && props.data.coverImage.image && props.data.coverImage.image.asset ?
                <Image alt={props.data.coverImage.text} image={props.data.coverImage.image.asset.gatsbyImageData}/>
                : null}
        </Comp>
    );
};

export default ProjectCard;