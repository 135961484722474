import React from 'react';
import styled from "styled-components";
import GlobalStyle from "../../style/GlobalStyle";
import "../../fonts/fonts.css";// fonts
import Footer from "./footer/Footer";
import FooterVideo from "./FooterVideo";
import Header from "./Header";
import Spacer from "./Spacer";
import {graphql, useStaticQuery} from "gatsby";
import TopGradient from "./TopGradient";
import Seo from "../seo";

const Page = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const BotGradient = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
    //background-color: ${({theme}) => theme.colors.accent};
  background-image: linear-gradient(rgba(248, 249, 250, 0) 60%, rgba(24, 91, 224, 0.1) 80%, ${({theme}) => theme.colors.accent});
  z-index: -200;
`

const Layout = ({children, ...props}) => {
    const generalData = useStaticQuery(graphql`
        {
            sanityPage(title: {fr: {eq: "General"}}) { # General page
                content {
                    ... on SanityContentString {
                        content {
                            _type
                            en
                            fr
                        }
                        identification {
                            id
                            section
                        }
                    }
                    ... on SanityContentText {
                        content {
                            _type
                            en
                            fr
                        }
                        identification {
                            id
                            section
                        }
                    }
                    ... on SanityContentUrl {
                        _type
                        identification {
                            id
                            section
                        }
                        link {
                            _type
                            en
                            fr
                        }
                        text {
                            _type
                            en
                            fr
                        }
                    }
                }
            }
        }
    `).sanityPage.content

    const footerData = generalData.filter(item => item.identification.section === "footer")
    const mainMenuData = generalData.filter(item => item.identification.section === "main menu")

    return (
        <Page>
                <Seo title={props.title}/>
                <GlobalStyle/>
                <TopGradient/>

                <Header
                    lang={props.lang}
                    mainMenuData={mainMenuData}
                    noLogo={props.noLogo}
                />

                {children}

                <Spacer s="large"/>
                <Footer data={footerData}/>

                {props.footerVideo ? <FooterVideo/> : null}

                <BotGradient/>
        </Page>
    );
};

export default Layout;