import {ThemeProvider} from "styled-components";
import React from "react";

const Theme = (props) => {
    const theme = {
        colors: {
            jadeBlue: "#185BE0",
            porcelain: "#F8F9FA",
            lemon: "#FFF200",
            white: "#FFFFFF",

            dropShadow: "rgba(24, 91, 224, 0.25)",
            insetLight: "rgba(248, 249, 250, 0.3)",

            get text() {
                return this.jadeBlue;
            },

            get background() {
                return this.porcelain;
            },

            get accent() {
                return this.jadeBlue;
            },

            // accent: "#185BE0",
            // accentLight: "rgba(24,91,224,0.1)",
            // background: "#F8F9FA",
            // background: "#CCCCCC",

            shade: "rgba(24,91,224,0.5)",
            light: "#FFFFFF"
        },

        fonts: {
            executive: {
                family: "Executive",

                // weights
                medium: "500",
                bold: "600",

                // sizes
                standard: "1.125rem",
            },
        },

        text: {
            smaller: {
                family: "executive",
                // size: "1.25rem", // 20px
                size: {
                    l: "1rem", // 16px
                    m: "1rem", // 16px
                },
                weight: "500",
                lineHeight: "auto",
                color: "text",
                transform: "none",
            },
            small: {
                family: "executive",
                // size: "1.25rem", // 20px
                size: {
                    l: "1.25rem", // 20px
                    m: "1.25rem", // 20px
                },
                weight: "500",
                lineHeight: "auto",
                color: "text",
                transform: "none",
            },
            standard: {
                family: "executive",
                // size: "1.563rem", // 25px
                size: {
                    l: "1.563rem", // 25px
                    m: "1.25rem", // 20px
                },
                weight: "500",
                lineHeight: "auto",
                color: "text",
                transform: "none",
            },
            medium: {
                family: "executive",
                // size: "2.188rem", // 35px
                size: {
                    l: "2.188rem", // 35px
                    m: "1.25rem", // 20px
                },
                weight: "500",
                lineHeight: "90%",
                color: "text",
                transform: "uppercase",
            },
            large: {
                family: "executive",
                // size: "2.188rem", // 35px
                size: {
                    l: "2.188rem", // 35px
                    m: "1.75rem", // 28px
                },
                weight: "500",
                lineHeight: "90%",
                color: "text",
                transform: "uppercase",
            },
            larger: {
                family: "executive",
                // size: "2.5rem", // 40px
                size: {
                    l: "2.5rem", // 40px
                    m: "1.25rem", // 20px
                },
                weight: "500",
                lineHeight: "90%",
                color: "text",
                transform: "none",
            },
            cardsField: {
                family: "executive",
                size: "0.875rem", // 14px
                weight: "500",
                lineHeight: "90%",
                color: "text",
                transform: "none",
            },
            cardsName: {
                family: "executive",
                // size: "3.125rem", // 50px
                size: {
                    l: "3.125rem", // 50px
                    m: "1.5rem", // 24px
                },
                weight: "500",
                lineHeight: "90%", // 45px
                color: "text",
                transform: "uppercase",
            },
            cardsTitle: {
                family: "executive",
                // size: "1.25rem", // 20px
                size: {
                    l: "1.25rem", // 20px
                    m: "0.875rem", // 14px
                },
                weight: "500",
                lineHeight: "90%",
                color: "text",
                transform: "none",
            },
            dropZone: {
                family: "executive",
                // size: "1.875rem", // 30px
                size: {
                    l: "1.875rem", // 30px
                    m: "1.5rem", // 24px
                },
                weight: "500",
                lineHeight: "auto",
                color: "text",
                transform: "uppercase",
            },
            buttonSmall: {
                family: "executive",
                size: "1.25rem", // 20px
                weight: "500",
                lineHeight: "1.25rem",
                color: "text",
                transform: "uppercase",
            },
            buttonStandard: {
                family: "executive",
                // size: "1.875rem", // 30px
                size: {
                    l: "1.875rem", // 30px
                    m: "1.25rem", // 20px
                },
                weight: "500",
                lineHeight: "90%",
                color: "text",
                transform: "uppercase",
            },
            bigTitle: {
                family: "executive",
                // size: "5rem", // 80px
                size: {
                    l: "5rem", // 80px
                    m: "2.5rem", // 40px
                },
                weight: "500",
                lineHeight: "90%",
                color: "text",
                transform: "uppercase",
            },
            mediumTitle: {
                family: "executive",
                // size: "3.125rem", // 50px
                size: {
                    l: "3.125rem", // 50px
                    m: "2.188rem", // 35px
                },
                weight: "500",
                lineHeight: "90%",
                color: "text",
                transform: "uppercase",
            },
            projectName: {
                family: "executive",
                // size: "3.125rem", // 50px
                size: {
                    l: "3.125rem", // 50px
                    m: "1.25rem", // 20px
                },
                weight: "500",
                lineHeight: "90%",
                color: "text",
                transform: "uppercase",
            },
            catalogueTitle: {
                family: "executive",
                // size: "2.5rem", // 40px
                size: {
                    l: "2.5rem", // 40px
                    m: "2rem", // 32px
                },
                weight: "500",
                lineHeight: "90%",
                color: "text",
                transform: "none",
            },
            catalogueDiploma: {
                family: "executive",
                size: "0.875rem", // 14px
                weight: "500",
                lineHeight: "100%",
                color: "text",
                transform: "uppercase",
            },
            catalogueName: {
                family: "executive",
                size: "1.25rem", // 20px
                weight: "500",
                lineHeight: "90%",
                color: "text",
                transform: "uppercase",
            },
        },

        sizes: {
            globalWidth: "73rem",
            globalMargins: "5rem",
            headerHeight: "4rem",
            headerHeightMobile: "3rem"
        },

        breakPoints: {
            // s: "37.5rem", // 600px
            xs: "26.563rem", // 768px
            s: "48rem", // 768px
            m: "64rem", // 1024px
            l: "83.125rem" // 1330px
        },

        spacer: {
            smallest: ["0.25rem", "0.25rem"],
            smaller: ["0.5rem", "0.5rem"],
            small: ["1rem", "0.5rem"],
            medium: ["2rem", "1rem"],
            large: ["4rem", "2rem"],
            larger: ["8rem", "4rem"],
        },

        paddings: {
            buttonLink: {
                vertical: "0.75rem",
                horizontal: "1.25rem",
            },
        },

        radii: {
            standard: "1.25rem",
            buttonLink: "0.375rem",
            tile: "1rem",
        },

        filters: {
            cardDropShadow: "drop-shadow(-5px 3px 10px rgba(24, 91, 224, 0.25))",
            titleDropShadow: "drop-shadow(0px 0px 10px rgba(24, 91, 224, 0.25))"
        },

        cards: {
            standardSize: "320px",
            smallSize: "250px",
            smallerSize: "200px",
            smallestSize: "150px",
            padding: "20px"
        },

        time: {
            date: "DD.MM.YYYY",
            dateTime: "DD.MM.YYYY - HH:mm",
        }
    };

    return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default Theme;