import React from 'react';
import styled from "styled-components";
import Spacer from "../Spacer";
import {HeadLogo} from "../../icons/HeadLogo";
import GlobalWidth from "../GlobalWidth";
import {useLanguageContext} from "../../../contexts/LanguageContext";
import SocialMedia from "./SocialMedia";
import {processData} from "../../../utils/utils";
import StandardButton from "../../UI/StandardButton";
import {HESSOLogo} from "../../icons/HESSOLogo";
import Text from "../Text";
import Link from "../../localization/Link";
import Conditional from "../Conditional";

const Comp = styled(GlobalWidth)`
  position: relative;
  width: 100%;
  //background-color: palegoldenrod;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: ${({theme}) => theme.breakPoints.m}) {
    flex-direction: column;
  }
`

const BotGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100%;
    //background-color: ${({theme}) => theme.colors.accent};
  background-image: linear-gradient(rgba(248, 249, 250, 0) 50%, rgba(248, 249, 250, 0.1) 60%, ${({theme}) => theme.colors.background});
  z-index: -150;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Left = styled(Column)`
  width: 20%;
  margin-right: auto;

  @media (max-width: ${({theme}) => theme.breakPoints.m}) {
    width: 100%;
    align-items: center;
  }
`

const Center = styled(Column)`
  align-items: center;
`

const Right = styled(Column)`
  width: 20%;
  align-items: flex-end;
  margin-left: auto;

  @media (max-width: ${({theme}) => theme.breakPoints.m}) {
    width: 100%;
    align-items: center;
  }
`

const FooterLink = styled(Link)`
  transition: all 0.4s;

  :hover {
    color: ${({theme}) => theme.colors.lemon};
  }
`

const Footer = (props) => {
    const language = useLanguageContext()

    return (
        <Comp>
            <Conditional desktop>
                <Spacer s="larger"/>
            </Conditional>

            <Container>
                <Left>
                    <FooterLink to="https://www.hesge.ch/head/">
                        <HeadLogo/>
                    </FooterLink>
                    <Spacer s="large"/>
                    <SocialMedia
                        facebook={processData(props.data, "id", "facebook")}
                        twitter={processData(props.data, "id", "twitter")}
                        instagram={processData(props.data, "id", "instagram")}
                        youtube={processData(props.data, "id", "youtube")}
                        linkedin={processData(props.data, "id", "linkedin")}
                    />
                </Left>
                <Conditional mobile breakPoint="m">
                    <Spacer s="large"/>
                </Conditional>

                <Center>
                    <StandardButton to="https://confirmsubscription.com/h/r/FB8CC020CCAF4A11">
                        {processData(props.data, "id", "newsletter button").content[language.lang]}
                    </StandardButton>
                </Center>
                <Conditional mobile breakPoint="m">
                    <Spacer s="large"/>
                </Conditional>

                <Right>
                    <FooterLink to="https://www.hesge.ch/geneve/">
                        <HESSOLogo/>
                    </FooterLink>
                </Right>
            </Container>
            <Spacer s="medium"/>

            <Text s="standard">
                {processData(props.data, "id", "copyright").content[language.lang]}
            </Text>
            <Spacer s="large"/>

            <BotGradient/>
        </Comp>
    );
};

export default Footer;