import React from 'react';
import styled from "styled-components";

const Comp = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, rgba(248, 249, 250, 1) 0%, rgba(248, 249, 250, 1) 20%, rgba(248, 249, 250, 0.5) 70%, rgba(248, 249, 250, 0) 100%);
  z-index: -100;
`

const TopGradient = (props) => {
    return (
        <Comp>
            
        </Comp>
    );
};

export default TopGradient;