import styled from "styled-components";

const Conditional = styled.div`
  ${(props) =>
          props.desktop
                  ? "display: contents;"
                  : props.mobile
                  ? "display: none;"
                  : null};

  @media (max-width: ${props => props.theme.breakPoints[props.breakPoint ? props.breakPoint : "s"]}) { // prev m
    ${(props) =>
            props.mobile
                    ? "display: contents;"
                    : props.desktop
                    ? "display: none;"
                    : null};
  }
`;

export default Conditional;
