import React from 'react';
import styled from "styled-components";
// import StandardButton from "../UI/StandardButton";
import {useProjectContext} from "../../contexts/ProjectsContext";
import SmallButton from "../UI/SmallButton";

// const Comp = styled.div`
//
// `

const Button = styled(SmallButton)`
  align-self: center;
`

const RemoveFilters = (props) => {
    const projects = useProjectContext();

    function removeFilters() {
        projects.removeAllFilters()
    }

    // console.log(projects.filters)

    if (projects.filtersOn) {
        return (
            <>
                <Button s="small" onClick={removeFilters}>
                    {props.text}
                </Button>
            </>
        );
    } else {
        return null
    }
};

export default RemoveFilters;