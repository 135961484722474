import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import DragScene from "./DragScene";
import BackgroundVideo from "./BackgroundVideo";
import DropZone from "../drag/DropZone";
import {randomInt} from "../../lib/utils";
import {navigate} from "gatsby"
import NextButton from "../UI/NextButton";

const Comp = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  //overflow: hidden;
`

const BottomButton = styled.div`
  width: 100%;
  //height: 20px;
  position: absolute;
  bottom: 1rem;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DropZoneContainer = styled.div`
  position: absolute;
  //top: 4rem;
  left: 20px;
  top: auto;
  bottom: 2.5rem;
  z-index: 6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${p => p.dragging ? p.theme.colors.accent : null};
  border-radius: ${({theme}) => theme.radii.standard};
  border: 1px solid ${p => p.dragging || p.hover ? p.theme.colors.background : p.theme.colors.accent};
  color: ${p => p.dragging ? p.hover ? p.theme.colors.background : p.theme.colors.background : p.theme.colors.accent};
  transition: all 0.4s;
  width: ${({theme}) => theme.cards.smallerSize};
  height: ${({theme}) => theme.cards.smallerSize};
    //height: ${p => p.theme.cards.standardSize};

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    width: ${({theme}) => theme.cards.smallestSize};
    height: ${({theme}) => theme.cards.smallestSize};
    top: auto;
    bottom: 2.7rem;
  }
`

const BottomGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 200px;
  left: 0;
  bottom: -198px;
  z-index: -5;

  background: linear-gradient(0deg, rgba(248, 249, 250, 0) 0%, rgba(248, 249, 250, 0.1) 15%, rgba(248, 249, 250, 0.225806) 34.9%, rgba(248, 249, 250, 1) 100%);
`

const compare = (a, b) => {
    if (a._id < b._id) {
        return -1;
    }
    if (a._id > b._id) {
        return 1;
    }
    return 0;
}

function countTags(array, value) {
    let count = 0;
    array.forEach((v) => (v._id === value && count++));
    return count;
}

function getRandom(arr, n) {
    let result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        const x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
}

const Introduction = (props) => {
    const onceRef = useRef(false)

    const [tag, setTag] = useState(null)
    const [tags, setTags] = useState(null)
    const [selProjects, setSelProjects] = useState(null)

    // set tag list
    useEffect(() => {
        if (!onceRef.current) {
            let tempTags = props.projects.map(item => {
                return item.tags.flat()
            }).flat()

            // remove duplicates
            tempTags = Array.from(new Set(tempTags.map(a => a._id)))
                .map(id => {
                    const tag = tempTags.find(a => a._id === id)
                    return {
                        name: tag.name,
                        _id: tag._id,
                        projects: countTags(tempTags, tag._id)
                    }
                }).sort(compare).filter(tag => {
                    return tag.projects > 5
                })
            setTags(tempTags)

            onceRef.current = true
        }
    }, [props.projects])

    // choose random tag
    useEffect(() => {
        const randomTag = tags ? tags[randomInt(0, tags ? tags.length : 0)] : null
        //
        setTag(randomTag)
    }, [tags])

    // select projects with tag
    useEffect(() => {
        setSelProjects(tag ? getRandom(props.projects.filter(i => i.tags.find(t => t._id === tag._id) && i.coverImage), 5) : null)
    }, [props.projects, tag])


    const [hover, setHover] = useState(-1)
    const [dragging, setDragging] = useState(false)
    const [dragItem, setDragItem] = useState(-1);

    // go to project when dropped on zone
    useEffect(() => {
        if (!dragging && hover > -1 && hover === dragItem && selProjects[dragItem]) {
            navigate(`/projects/${selProjects[dragItem].slug.current}`)
        }
    }, [dragItem, dragging, hover, selProjects])

    const targetRef = useRef()

    return (
        <Comp>
            {/*<DragScene*/}
            {/*    tag={tag}*/}
            {/*    projects={selProjects}*/}
            {/*    objRef={objRef}*/}
            {/*/>*/}
            <BackgroundVideo
                tag={tag}
            />
            <BottomGradient/>

            <BottomButton>
                <NextButton text={props.data.find(item => item.identification.id === "browse next").content}/>
            </BottomButton>

            <DropZoneContainer ref={targetRef} hover={hover > -1 && hover === dragItem ? 1 : 0} dragging={dragging ? 1 : 0}>
                <DragScene
                    tag={tag}
                    projects={selProjects}
                    targetRef={targetRef}
                    setHover={setHover}
                    setDragging={setDragging}
                    dragItem={dragItem}
                    setDrag={setDragItem}
                />
                <DropZone
                    hover={hover > -1 && hover === dragItem ? 1 : 0}
                    dragging={dragging ? 1 : 0}
                    text={props.data.find(item => item.identification.id === "drop zone").content}
                />
            </DropZoneContainer>
        </Comp>
    );
};

export default Introduction;