import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
	width: auto;
	height: 100%;
	color: inherit;
`;

export const Facebook = () => (
	<Svg viewBox="0 0 50 49.7">
		<g>
			<path
				fill="currentColor"
				d="M50,25C50,11.19,38.81,0,25,0S0,11.19,0,25c0,12.48,9.14,22.82,21.09,24.7V32.23h-6.35V25h6.35v-5.51
		c0-6.27,3.73-9.73,9.44-9.73c2.74,0,5.6,0.49,5.6,0.49v6.15h-3.15c-3.11,0-4.07,1.93-4.07,3.9V25h6.93l-1.11,7.23h-5.83V49.7
		C40.86,47.82,50,37.48,50,25z"
			/>
		</g>
	</Svg>
);
