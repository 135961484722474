import ObjectPath from "object-path"

const genFilterOptions = (projectsArr, id) => {
    const paths = {
        authors: "authors",
        tutors: "tutors",
        diplomas: ["authors", "0", "diploma"]
    }

    function dynamicContent(item, id) {
        if (id === "authors") {
            return item ? {
                value: item._id,
                label: item.firstName + " " + item.lastName,
                path: "authors._id"
            } : {value: "", label: "", path: ""}
        } else if (id === "tutors") {
            return {value: item._id, label: item.name, path: "tutors._id"}
        } else if (id === "diplomas") {
            if (item) {
                return {value: item._id, label: item.name, path: "authors.diploma._id"}
            } else return {}
        } else if (id === "fields") {
            if (item && item.authors.length > 0) {
                return {
                    value: item.authors[0].diploma.field._id,
                    label: item.authors[0].diploma.field.name,
                    path: "authors.diploma.field._id"
                }
            } else return {}
        } else if (id === "options") {
            if (item && item.authors.length > 0 && item.authors[0].option) {
                return {
                    value: item.authors[0].option._id,
                    label: item.authors[0].option.name,
                    path: "authors.option._id"
                }
            } else return {}
        } else if (id === "media") {
            // console.log(item.media)
            if (item.media && Array.isArray(item.media)) {
                return item.media.map(i => {
                    return {
                        value: i._id,
                        label: i.name,
                        path: "media._id"
                    }
                })
            } else return {}
        } else if (id === "awards") {
            // console.log(item.media)
            if (item.awards && Array.isArray(item.awards)) {
                return item.awards.map(i => {
                    return {
                        value: i._id,
                        label: i.name,
                        path: "awards._id"
                    }
                })
            } else return {}
        } else if (id === "tags") {
            // console.log(item.media)
            if (item.tags && Array.isArray(item.tags)) {
                return item.tags.map(i => {
                    return {
                        value: i._id,
                        label: i.name,
                        path: "tags._id"
                    }
                })
            } else return {}
        }
    }

    const compare = (a, b) => {
        if (a.label < b.label) {
            return -1;
        }
        if (a.label > b.label) {
            return 1;
        }
        return 0;
    }

    let options = projectsArr.map((item) => {
        if (Array.isArray(item[paths[id]])) {
            return item[paths[id]].map((subItem) => {
                return dynamicContent(subItem, id)
            })
        } else {
            return dynamicContent(ObjectPath.get(item, paths[id]), id)
        }
    }).flat()

    // filter out empty options
    options = options.filter(i => Object.keys(i).length > 0)

    // remove duplicates
    return options = Array.from(new Set(options.map(a => a.value)))
        .map(id => {
            return options.find(a => a.value === id)
        }).sort(compare)
};

export default genFilterOptions;