import React from 'react';
import styled from "styled-components";
import Text from "../layout/Text";
import Link from "../localization/Link";
import {GatsbyImage} from "gatsby-plugin-image";

const Comp = styled(Link)`
  border-radius: ${({theme}) => theme.radii.standard};
  width: ${({theme}) => theme.cards.standardSize};
  height: ${({theme}) => theme.cards.standardSize};
  background-color: ${({theme}) => theme.colors.background};
  background-image: url(${p => p.image});
  background-size: 100%;
  background-position: center;
  padding: ${({theme}) => theme.cards.padding};
  transform: rotateZ(${p => p.rot}deg);
  transition: all 0.4s;
  filter: ${({theme}) => theme.filters.cardDropShadow};
  margin: 1rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  display: flex;
  align-items: flex-end;
  
  align-self:${p => p.index %2 === 0 ? "flex-start": "flex-end"};

  :hover {
    transform: rotateZ(0);
    
    .text {
      transition: all 0.4s;
      color: ${({theme}) => theme.colors.background};
    }
    .gradient {
      //background: linear-gradient(180deg, rgba(24, 91, 224, 0) 58.85%, #185BE0 77.6%)
      background: none;
      background-color: ${({theme}) => theme.colors.accent};
    }
  }

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    width: calc(${({theme}) => theme.cards.smallerSize} + 5vw);
    height: calc(${({theme}) => theme.cards.smallerSize} + 5vw);
  }

  //:hover div {
  //  background: linear-gradient(180deg, rgba(24, 91, 224, 0) 58.85%, #F8F9FA 77.6%)
  //}
`

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(248, 249, 250, 0) 58.85%, #F8F9FA 77.6%);
  z-index: -1;
  transition: all 0.4s;
`

const Image = styled(GatsbyImage)`
  position: absolute!important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
`

const FieldCard = (props) => {
    const rot = 2;

    return (
        <Comp
            index={props.index}
            to={props.link}
            // image={props.image}
            rot={Math.random() * (rot - -rot) + -rot}
        >
            <Gradient className="gradient"/>

            <Text s="medium" className="text">
                {props.title}
            </Text>
            <Image alt={props.title} image={props.image}/>
        </Comp>
    );
};

export default FieldCard;