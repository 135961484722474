import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import useFilters from "../hooks/useFilters";
import genFilterOptions from "../hooks/genFilterOptions";

export const ProjectsContext = createContext();

// We wrap the provider in a nice little component
// which will hold the state and provide methods to
// update the state
function ProjectProvider(props) {
    // contains all projects loaded from static query, is never modified
    const [allProjects, setAllProjects] = useState([])
    // contains projects, whether filtered or not
    const [projects, setProjects] = useState([])

    // FILTERS------------------------------------------------------
    const emptyFilters = useMemo(() => {
        return {
            authors: [],
            fields: [],
            diplomas: [],
            options: [],
            tutors: [],
            media: [],
            awards: [],
            tags: [],
        }
    }, [])

    const [filtersOn, setFiltersOn] = useState(false)
    const [filters, setFilters] = useState(emptyFilters)

    function addFilters(content, type) {
        setFilters({...filters, [type]: content})
    }

    function removeFilters(content, type) {
        let tFilters = filters[type];
        tFilters.splice(tFilters.findIndex(i => i.value === content.value), 1)

        setFilters({...filters, [type]: tFilters})
    }

    function removeAllFilters() {
        setFilters(emptyFilters)
    }

    // function to check if filters are empty or not
    const checkFilters = useCallback(() => {
        let on = false;

        for (const key in filters) {
            if (filters[key].length > 0) {
                on = true
                break
            }
        }
        return on
    }, [filters])

    // call check function when projects change
    useEffect(() => {
        setFiltersOn(checkFilters())
    }, [filters, checkFilters])

    // FILTER OPTIONS------------------------------------------------------
    const [filterOptions, setFilterOptions] = useState({})

    // load filters options
    useEffect(() => {
        // const options = Object.keys(emptyFilters).map(item => {
        //     return (genFilterOptions(filters[props.id] && filters[props.id].length > 0 ? allProjects : projects, item))
        // })
        let options = {}

        for (let i = 0; i < Object.keys(emptyFilters).length; i++) {
            const id = Object.keys(emptyFilters)[i]
            options[id] = genFilterOptions(filters[id] && filters[id].length > 0 ? allProjects : projects, id)
        }

        setFilterOptions(options)
    }, [filters, allProjects, projects, emptyFilters])


    // URL HASH------------------------------------------------------
    // load hash from filters
    useEffect(() => {
        function formatFilters() {
            return Object.keys(filters).filter(i => filters[i].length > 0).map(filter => {
                const values = filters[filter].map(item => item.value)
                return (
                    `${filter}=${values.join(",")}`
                )
            }).join("&")
        }

        document.location.hash = formatFilters()
    }, [filters])
    // http://localhost:8000/catalogue#diplomas=48789424-c294-4c81-8238-fbfea5a59872

    const [hash, setHash] = useState(null)

    // load filters from hash
    useEffect(() => {

        const flatOptions = () => {
            let allOptions = []
            for (const value of Object.values(filterOptions)) {
                allOptions.push(value)
            }
            return allOptions.flat()
        }

        function parseHash(hash, options) {
            // let hash = document.location.hash

            if (hash) {
                hash = hash.replace(/#/i, "")
                const splitAmpersand = hash.split("&")

                let allOptions = flatOptions()

                if (allOptions.length > 0) {
                    let hashFilters = {}
                    for (let i = 0; i < splitAmpersand.length; i++) {
                        const splitEqual = splitAmpersand[i].split("=")
                        const ids = splitEqual[1].split(',')
                        const objects = ids.map(item => {
                            return allOptions.find(i => i.value === item)
                        })
                        hashFilters[splitEqual[0]] = objects
                    }

                    return hashFilters
                } else {
                    return null
                }
            }
        }

        if (hash) {
            if (flatOptions().length > 0 && hash && hash.length > 0) {
                setFilters(parseHash(hash, filterOptions))
                setHash(null)
            }
        }
    }, [hash, filterOptions])

    const [numberOfDisplayedProjects, setNumberOfDisplayedProjects] = useState(0)

    function displayedProjects(count) {
        setNumberOfDisplayedProjects(count)
    }

    const filteredProjects = useFilters(allProjects, filters)
    // load projects from allProjects according to filters
    useEffect(() => {
        setProjects(filtersOn ? filteredProjects : allProjects)
    }, [setProjects, allProjects, filteredProjects, filtersOn])

    const projectData = {
        allProjects,
        setAllProjects,
        projects,
        filters,
        addFilters,
        removeFilters,
        removeAllFilters,
        filtersOn,
        numberOfDisplayedProjects,
        displayedProjects,
        filterOptions,
        setHash
    };

    return <ProjectsContext.Provider value={projectData} {...props} />;
}

// Here we create a custom hook that allows us to consume
// the context
function useProjectContext() {
    return useContext(ProjectsContext);
}

export {ProjectProvider, useProjectContext};
