import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
  width: auto;
  height: 1rem;
  color: ${({theme}) => theme.colors.accent};
`;

export const Search = (props) => (
    <Svg viewBox="0 0 16 16" focus={props.focus}>
        <path
            d="M16 14.1908L11.7394 9.92996C12.4098 8.9174 12.8001 7.70514 12.8001 6.40069C12.7998 2.86517 9.93464 0 6.40006 0C2.86517 0 0 2.86517 0 6.40038C0 9.93464 2.86517 12.8008 6.40006 12.8008C7.7042 12.8008 8.91771 12.4092 9.92933 11.7391L14.1902 16L16 14.1908ZM1.81354 6.40163C1.81354 3.86835 3.8671 1.81573 6.40006 1.81573C8.93334 1.81573 10.9869 3.86835 10.9869 6.40163C10.9869 8.9349 8.93334 10.9888 6.40006 10.9888C3.8671 10.9885 1.81354 8.9349 1.81354 6.40163Z"
            fill="currentColor"/>
    </Svg>
);
