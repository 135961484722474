import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
	width: auto;
	height: 2rem;
	color: inherit;
	//mix-blend-mode: difference;
	//background-color: red;
`;

export const HeadLogo = () => (
	<Svg viewBox="0 0 500 253.37">
		<g>
			<path
				fill="currentColor"
				d="M447.28,208.4c0.25-10.24,5.5-16.74,13.74-16.74c9,0,12.99,6.25,13.24,16.74H447.28L447.28,208.4L447.28,208.4z
		 M474.76,228.64c-2,7.5-7,9.75-12.74,9.75c-8.75,0-14.49-5.25-14.74-17.99H493c0.25-2.5,0.5-5.25,0.5-8
		c0-21.74-12.99-35.48-31.98-35.48c-20.49,0-33.98,14.49-33.98,37.73s12.24,38.23,34.23,38.23c18.74,0,27.49-11.74,29.74-22.24
		L474.76,228.64L474.76,228.64L474.76,228.64z M366.57,178.91h-20.99l26.49,71.96h17.74l26.49-71.96h-20.24l-9.75,31.48
		c-2,6.75-3.25,11.49-4.75,17.99h-0.25c-1.5-6.25-2.75-11.24-4.75-17.99L366.57,178.91L366.57,178.91L366.57,178.91z M319.59,171.41
		l4-7.75l-34.48-22.24l-7,15.49L319.59,171.41L319.59,171.41L319.59,171.41z M288.36,208.4c0.25-10.24,5.5-16.74,13.74-16.74
		c9,0,12.99,6.25,13.24,16.74H288.36L288.36,208.4L288.36,208.4z M315.59,228.64c-2,7.5-7,9.75-12.74,9.75
		c-8.75,0-14.49-5.25-14.74-17.99h45.73c0.25-2.5,0.5-5.25,0.5-8c0-21.74-12.99-35.48-31.98-35.48c-20.49,0-33.98,14.49-33.98,37.73
		s12.24,38.23,34.23,38.23c18.74,0,27.49-11.74,29.74-22.24L315.59,228.64L315.59,228.64L315.59,228.64z M204.15,188.41
		c-0.25-3-0.25-6.5-0.5-9.5h-18.74c0.5,7.25,0.75,15.74,0.75,22.74v49.23h19.74v-39.98c0-13.49,7-17.99,13.24-17.99
		c4,0,7,1.75,8.5,4c1.75,2.75,2.5,6,2.5,11.24v42.48h19.74v-45.48c0-8-1.75-14.24-5.25-18.99c-3.75-5.5-9.75-9-19.99-9
		c-7.75,0-16.49,4.5-19.74,11.24H204.15L204.15,188.41L204.15,188.41z M119.44,208.4c0.25-10.24,5.5-16.74,13.74-16.74
		c9,0,12.99,6.25,13.24,16.74H119.44L119.44,208.4L119.44,208.4z M146.68,228.64c-2,7.5-7,9.75-12.74,9.75
		c-8.75,0-14.49-5.25-14.74-17.99h45.98c0.25-2.5,0.5-5.25,0.5-8c0-21.74-12.99-35.48-31.98-35.48c-20.49,0-33.98,14.49-33.98,37.73
		s12.24,38.23,34.23,38.23c18.74,0,27.49-11.74,29.74-22.24L146.68,228.64L146.68,228.64L146.68,228.64z M78.96,251.12v-57.97H41.23
		v15.49h18.49v1.25c0,14.74-5.5,25.49-17.99,25.49c-11.49,0-18.99-9.99-18.99-36.48s7-36.73,19.49-36.73
		c9.5,0,14.24,6.25,15.24,17.49l20.99-3c-1-13.99-10.49-32.48-35.98-32.48C14.99,144.18,0,164.67,0,198.9
		c0,36.23,16.99,54.47,40.23,54.47c10.49,0,19.49-5.5,22.99-15.24h0.25l2.25,12.99L78.96,251.12L78.96,251.12L78.96,251.12z"
			/>
			<path
				fill="currentColor"
				d="M477.76,52.22c0,19.99-5.25,34.73-20.49,34.73h-12.24V17.24h10.24C473.76,17.24,477.76,34.73,477.76,52.22L477.76,52.22
		L477.76,52.22z M500,50.47c0-20.74-7-40.23-25.24-47.48c-4.75-2-10.49-3-17.24-3h-34.23v104.2h34.23c6.75,0,12.24-1,17.24-2.75
		C493.5,94.7,500,75.21,500,50.47L500,50.47L500,50.47z M372.06,68.22h-21.49l5.5-21.24c1.75-6.5,3.75-13.99,5.25-21.99h0.25
		c1.5,7.75,3.75,15.49,5.25,21.74L372.06,68.22L372.06,68.22L372.06,68.22z M404.8,104.2L372.06,0h-20.74l-32.73,104.2h22.24
		l5.25-19.24h30.73l5.25,19.24H404.8L404.8,104.2L404.8,104.2z M304.35,104.2V86.21h-42.98V58.97h34.98V41.98h-34.98V17.99h42.98V0
		h-64.97v104.2H304.35L304.35,104.2L304.35,104.2z M210.14,104.2V0h-21.99v41.98h-29.24V0h-21.99v104.2h21.99V59.97h29.24v44.23
		H210.14L210.14,104.2L210.14,104.2z M103.45,55.72v-8.5H7.75v8.5H103.45L103.45,55.72L103.45,55.72z"
			/>
		</g>
	</Svg>
);
