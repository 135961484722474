
export const createLocaleTextGetter = languageCode => {
	const languages = [languageCode, "fr"]; // last language in array is default;

	const localize = value => {
		if (value) {
			if (Array.isArray(value)) {
				return value.map(v => localize(v, languages));
			} else if (typeof value == "object") {
				if (/^locale[A-Z]/.test(value._type)) {
					const language = languages.find(lang => value[lang]);
					// if (value._type === "localeBlock") {
					// 	console.log(value);
					// 	console.log(language);
					// }

					if (language) {
						if (value[language]) {
							return value[language];
						} else {
							return value["fr"];
						}
					} else {
						if (languages[0] && value["_rawEn"]) {
							return value["_rawEn"];
						} else {
							return value["_rawFr"];
						}
					}
				}
				return Object.keys(value).reduce((result, key) => {
					result[key] = localize(value[key], languages);
					return result;
				}, {});
			}
			return value;
		} else {
			return value;
		}
	};

	return localize;
};

export function trimText(text, charCount) {
	const truncated = text.slice(0, charCount);
	const trail = text.slice(charCount, charCount + 1) ? " [...]" : "";

	return truncated + trail;
}

export function processData(data, type, id) {
	return data.find(item => item.identification[type] === id)
}