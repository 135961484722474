import React from 'react';
import styled from "styled-components";
// import Image from "../../assets/images/campus.jpg"

const Comp = styled.div`
  border-radius: ${({theme}) => theme.radii.standard};
  overflow: hidden;
  filter: ${({theme}) => theme.filters.cardDropShadow};
  max-height: 80vh;
  transform: rotateZ(-0.5deg);
  width: 100%;

  iframe {
    width: 100%;
    height: 80vh;
  }
`

const VisitCard = (props) => {
    return (
        <Comp>
            {/*<img src={Image} alt="campus HEAD"/>*/}
            <iframe src={props.data.link}
                    name={props.data.title}
                    title={props.data.title}
                    frameBorder="0"
                    allow="fullscreen; accelerometer; gyroscope; magnetometer; vr; xr; xr-spatial-tracking; autoplay; camera; microphone"
                    allowFullScreen={true}
            />
        </Comp>
    );
};

export default VisitCard;