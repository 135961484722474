import React from 'react';
import {TriangleArrow} from "../icons/TriangleArrow";
import styled from "styled-components";
import Spacer from "../layout/Spacer";
import Text from "../layout/Text";

const Comp = styled.div`
  display: flex;
  align-items: center;
  color: ${({theme}) => theme.colors.accent};
  height: 0.5rem;
`

const NextButton = (props) => {
    return (
        <Comp>
            <TriangleArrow/>
            <Spacer s="small"/>

            <Text s="buttonSmall">
                {props.text}
            </Text>

            <Spacer s="small"/>
            <TriangleArrow/>
        </Comp>
    );
};

export default NextButton;