import {createGlobalStyle} from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    ::selection {
      background-color: ${({theme}) => theme.colors.accent};
      color: ${({theme}) => theme.colors.background};
    }
  }

  html {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    overflow-x: hidden;
    background-color: ${({theme}) => theme.colors.background};
    font-family: ${({theme}) => theme.fonts[theme.text.standard.family].family}, sans-serif;
      //font-size: ${({theme}) => theme.text.standard.size};
    font-weight: ${({theme}) => theme.text.standard.weight};
    line-height: ${({theme}) => theme.text.standard.lineHeight};
    color: ${({theme}) => theme.colors[theme.text.standard.color]};
    //scroll-behavior: smooth;
  }

  body {
    margin: 0;
    padding: 0;
    border: 0;
  }

  a {
    color: ${({theme}) => theme.colors.accent};
    text-decoration: none;

    :hover {
      text-decoration: none;
    }
  }

  input {
    font-family: ${({theme}) => theme.fonts[theme.text.buttonSmall.family].family}, sans-serif;
      //font-size: ${({theme}) => theme.text.buttonSmall.size};
    font-weight: ${({theme}) => theme.text.buttonSmall.weight};
    line-height: ${({theme}) => theme.text.buttonSmall.lineHeight};
    color: ${({theme}) => theme.colors.accent};
  }

  *:focus {
    outline: none;
  }
  
  .vimeo_embed {
    position: relative;
    padding-bottom: 56.25%; /* 41.66666667% */
    overflow: hidden;
    max-width: 100%;
  }

  .vimeo_embed iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export default GlobalStyle;