import React from 'react';
import styled from "styled-components";
import FilterSelect from "./FilterSelect";
import GlobalWidth from "../layout/GlobalWidth";

const Comp = styled(GlobalWidth)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
    //background-color: ${({theme}) => theme.colors.background};
`

const FilterBar = (props) => {
    const {data} = props

    const authors = data.find(i => i.identification.id === "authors").content
    const fields = data.find(i => i.identification.id === "fields").content
    const diplomas = data.find(i => i.identification.id === "diplomas").content
    const options = data.find(i => i.identification.id === "options").content
    const tutors = data.find(i => i.identification.id === "tutors").content
    // const media = data.find(i => i.identification.id === "media").content
    const awards = data.find(i => i.identification.id === "awards").content
    const tags = data.find(i => i.identification.id === "tags").content

    return (
        <Comp>
            <FilterSelect
                title={authors}
                id={"authors"}
            />

            <FilterSelect
                title={fields}
                id={"fields"}
            />

            <FilterSelect
                title={diplomas}
                id={"diplomas"}
            />

            <FilterSelect
                title={options}
                id={"options"}
            />

            <FilterSelect
                title={tutors}
                id={"tutors"}
            />

            <FilterSelect
                title={awards}
                id={"awards"}
            />

            {/*<FilterSelect*/}
            {/*    title={media}*/}
            {/*    id={"media"}*/}
            {/*/>*/}

            <FilterSelect
                title={tags}
                id={"tags"}
            />
        </Comp>
    );
};

export default FilterBar;