import React from 'react';
import styled from "styled-components";
import PullRelease from "../drag/PullRelease";
import DragCard from "../drag/DragCard";

const Comp = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  //top: -4rem;
  left: -20px;
  //top: auto;
  bottom: -2.5rem;
  color: ${({theme}) => theme.colors.lemon};
  z-index: 10;
  overflow: hidden;
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //justify-content: center;
  //background-color: palegoldenrod;

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
  }
`

const DragScene = (props) => {

    const projectCards = props.projects ? props.projects.map((item, index) => {
        return (
            <PullRelease
                index={index}
                dragItem={props.dragItem}
                setDrag={props.setDrag}
                key={index}
                setDragging={props.setDragging}
            >
                <DragCard
                    targetRef={props.targetRef}
                    dragItem={props.dragItem}
                    index={index}
                    field={item.authors[0].diploma.name}
                    name={[item.authors[0].firstName, item.authors[0].lastName]}
                    title={item.title}
                    image={item.coverImage}
                    setHover={props.setHover}
                />
            </PullRelease>
        )
    }) : null

    return (
        <Comp>
            {projectCards}
            {/*<PullRelease*/}
            {/*    index={0}*/}
            {/*    dragItem={dragItem}*/}
            {/*    setDrag={setDrag}*/}
            {/*>*/}
            {/*    <DragCard*/}
            {/*        field="Diploma Arts Visuels"*/}
            {/*        name="Allison Geidt"*/}
            {/*        title="Danse en contrechamp"*/}
            {/*    />*/}
            {/*</PullRelease>*/}
            {/*<PullRelease*/}
            {/*    index={0}*/}
            {/*    dragItem={dragItem}*/}
            {/*    setDrag={setDrag}*/}
            {/*>*/}
            {/*    <DragCard*/}
            {/*        field="MA Cinéma"*/}
            {/*        name="Anna-luisa Oltoro-Gradinote"*/}
            {/*        title="Restaurant de bord de mer"*/}
            {/*    />*/}
            {/*</PullRelease>*/}
        </Comp>
    );
};

export default DragScene;