import React from 'react';
import styled from "styled-components";
import Text from "../layout/Text";
import Spacer from "../layout/Spacer";

const Comp = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  //background-color: ${({theme}) => theme.colors.background};
  align-self: ${p => p.menu ? "center" : "flex-end"};

  @media (max-width: ${({theme}) => theme.breakPoints.s}) { // prev m
    //flex-direction: column;
    align-items: center;
    width: 100%;
  }
`

const ProjectsNumber = (props) => {
    return (
        <Comp menu={props.menu}>
            <Text s="larger">
                {props.displayed} / {props.number}
            </Text>
            <Spacer s="small"/>

            <Text s="small">
                {props.text}
            </Text>
        </Comp>
    );
};

export default ProjectsNumber;