import React from 'react';
import styled from "styled-components";
import Text from "../layout/Text";
import GlobalWidth from "../layout/GlobalWidth";
import Spacer from "../layout/Spacer";

const Comp = styled(GlobalWidth)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  filter: ${({theme}) => theme.filters.titleDropShadow};
`

const ProjectTitle = (props) => {
    return (
        <Comp>
            <Text s="bigTitle">
                {props.title}
            </Text>
            <Spacer s="medium"/>

            <Text s="projectName">
                {props.name}
            </Text>
        </Comp>
    );
};

export default ProjectTitle;