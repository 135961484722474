import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import Link from "../localization/Link";
import Text from "../layout/Text";
import styled from "styled-components";
import Spacer from "../layout/Spacer";

const BlockLink = styled(Link)`
  cursor: pointer;
  text-underline-offset: 0.1em;
  text-decoration-thickness: 1px;
  text-decoration: underline;
  width: fit-content;

  :hover {
    background-color: ${({theme}) => theme.colors.accent};
    color: ${({theme}) => theme.colors.background};
    text-decoration: none;
  }
`

const Block = (props) => {

    const serializers = {
        // container: props => (<BlockContainer article>{props.children}</BlockContainer>),
        types: {
            block: props => {
                return (
                    <>
                        <Text
                            // as="span"
                            s={props.node.style === "h1" ? "large" : "standard"}
                            // style={{ display: "block" }}
                        >
                            {props.children}
                        </Text>
                        {/*{props.node.style === "h1" ? <Spacer s={"small"}/> : null}*/}
                        <Spacer s={"small"}/>
                    </>
                );
            },
        },
        marks: {
            h1: props => (
                <Text
                    s="standard"
                >
                    {props.children}
                </Text>
            ),
            link: props => {
                return (
                    <BlockLink to={props.mark.url}>
                        {props.children}
                    </BlockLink>
                );
            },
        },
    };

    return (
        <BlockContent
            blocks={props.data}
            serializers={serializers}
        />
    );
};

export default Block;