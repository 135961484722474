import React from "react"
import {ProjectProvider} from "./src/contexts/ProjectsContext";
import Theme from "./src/style/Theme";
import {LanguageProvider} from "./src/contexts/LanguageContext";

export const wrapRootElement = ({element}) => {

    return (
        <Theme>
            <LanguageProvider>
                <ProjectProvider>
                    {element}
                </ProjectProvider>
            </LanguageProvider>
        </Theme>
    )
}