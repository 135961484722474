import React, {useState} from 'react';
// import styled from "styled-components";
import Layout from "../components/layout/Layout";
import {graphql} from "gatsby";
import Projects from "../components/02_catalogue/Projects";
import localize from "../components/localization/localize";
import PageTitle from "../components/UI/PageTitle";
import FilterBar from "../components/02_catalogue/FilterBar";
import Spacer from "../components/layout/Spacer";
import FiltersSummary from "../components/02_catalogue/FiltersSummary";
import CatalogueBackground from "../components/02_catalogue/CatalogueBackground";
import ScrollButton from "../components/UI/ScrollButton";
import Conditional from "../components/layout/Conditional";
import MobileFilters from "../components/02_catalogue/MobileFilters";

// const Comp = styled.div`
//
// `

const Catalogue = ({data, ...props}) => {
    const projectsData = data.allSanityProject.nodes
    const pageData = data.sanityPage.content

    const titleData = pageData.filter(item => item.identification.section === "title" && item.identification.id === "title")[0]
    const filterData = pageData.filter(item => item.identification.section === "filters")
    const summaryData = pageData.filter(item => item.identification.section === "summary")
    const loadMore = pageData.filter(item => item.identification.section === "loadmore" && item.identification.id === "button")[0].content
    const scrollTop = pageData.filter(item => item.identification.section === "scrolltop" && item.identification.id === "button")[0].content

    const [mobileFilters, setMobileFilters] = useState(false)

    return (
        <>
            <Layout lang={props.pageContext.locale} title={data.sanityPage.title} noLogo>
                <PageTitle>
                    {titleData.content}
                </PageTitle>
                <Conditional mobile>
                    <Spacer s="medium"/>
                </Conditional>

                <Conditional desktop>
                    <FilterBar projects={projectsData} data={filterData}/>
                </Conditional>
                <Conditional mobile>
                    {mobileFilters ? <MobileFilters
                        projects={projectsData}
                        data={filterData}
                        summary={summaryData}
                        open={mobileFilters}
                        set={setMobileFilters}
                    /> : null}
                </Conditional>

                <FiltersSummary data={summaryData} setMobileFilters={setMobileFilters}/>
                <Spacer s="large"/>

                <Projects
                    hash={props.location.hash}
                    projects={projectsData}
                    loadMore={loadMore}
                />

                <ScrollButton text={scrollTop}/>

                <CatalogueBackground/>
            </Layout>
        </>
    );
};

export default localize(Catalogue);

export const query = graphql`
    {
        sanityPage(title: {fr: {eq: "Catalogue"}}) {
            title {
                _type
                en
                fr
            }
            content {
                ... on SanityContentString {
                    content {
                        _type
                        en
                        fr
                    }
                    identification {
                        id
                        section
                    }
                }
                ... on SanityContentText {
                    content {
                        _type
                        en
                        fr
                    }
                    identification {
                        id
                        section
                    }
                }
            }
        }
        allSanityProject(
            sort: {order: ASC, fields: title}
        ) {
            nodes {
                _id
                title
                coverImage {
                    text {
                        _type
                        en
                        fr
                    }
                    image {
                        asset {
                            gatsbyImageData(fit: CLIP, placeholder: DOMINANT_COLOR)
                        }
                    }
                }
                authors {
                    _id
                    firstName
                    lastName
                    diploma {
                        _id
                        name {
                            _type
                            en
                            fr
                        }
                        field {
                            _id
                            name {
                                _type
                                en
                                fr
                            }
                        }
                    }
                    option {
                        name {
                            _type
                            en
                            fr
                        }
                        _id
                    }
                }
                tutors {
                    name
                    _id
                }
                media {
                    _id
                    name {
                        _type
                        en
                        fr
                    }
                }
                tags {
                    _id
                    name {
                        _type
                        en
                        fr
                    }
                }
                awards {
                    _id
                    name {
                        _type
                        en
                        fr
                    }
                }
                slug {
                    current
                }
            }
        }
    }
`