import React from 'react';
// import styled from "styled-components";
import Layout from "../components/layout/Layout";
import PageTitle from "../components/UI/PageTitle";
import {graphql} from "gatsby";
import localize from "../components/localization/localize";
import Spacer from "../components/layout/Spacer";
import Credits from "../components/05_about/Credits";

// const Comp = styled.div`
//
// `

const About = ({data, ...props}) => {
    const pageData = data.sanityPage.content
    const titleData = pageData.filter(item => item.identification.section === "title" && item.identification.id === "title")[0].content
    const credits = pageData.filter(item => item.identification.section === "credits" && item.identification.id === "credits")
    // console.log(data)

    return (
        <Layout lang={props.pageContext.locale}  footerVideo title={data.sanityPage.title}>
            <PageTitle>
                {titleData}
            </PageTitle>
            <Spacer s="large"/>

            <Credits data={credits}/>
        </Layout>
    );
};

export default localize(About);

export const query = graphql`
    {
        sanityPage(title: {en: {eq: "About"}}) {
            title {
                _type
                en
                fr
            }
            content {
                ... on SanityContentString {
                    content {
                        _type
                        en
                        fr
                    }
                    identification {
                        id
                        section
                    }
                }
                ... on SanityContentText {
                    content {
                        _type
                        en
                        fr
                    }
                    identification {
                        id
                        section
                    }
                }
                ... on SanityContentBlock {
                    _type
                    content {
                        _rawEn
                        _rawFr
                        _type
                    }
                    identification {
                        id
                        section
                    }
                }
            }
        }
    }
`