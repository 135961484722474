import styled from "styled-components";

const Text = styled.div`
  font-family: ${p => p.theme.fonts[p.theme.text[p.s].family].family}, sans-serif;
  font-size: ${p => p.theme.text[p.s].size.l ? p.theme.text[p.s].size.l : p.theme.text[p.s].size};
  font-weight: ${p => p.w ? p.theme.fonts[p.theme.text[p.s].family][p.w] : p.theme.text[p.s].weight};
  line-height: ${p => p.theme.text[p.s].lineHeight};
    // color: ${p => p.c ? p.theme.colors[p.c] : p.theme.colors[p.theme.text[p.s].color]};
  color: inherit;
  text-transform: ${p => p.t ? p.t : p.theme.text[p.s].transform};
  font-style: ${p => p.st === "italic" ? p.st : p.theme.text[p.s].style ? p.theme.text[p.s].style : null};
  text-align: ${p => p.a ? p.a : null};

  @media (max-width: ${p => p.theme.breakPoints[p.breakPoint ? p.breakPoint : "s"]}) {
    font-size: ${p => p.theme.text[p.s].size.m};
  }
`;

export default Text;
