import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
  width: auto;
  height: ${p => p.h ? p.h : "0.75rem"};
  color: inherit;
  transform: rotateZ(${p => p.r});
  margin-top: ${p => p.focus ? "0" : "2px"};
`;

export const SelectArrow = (props) => (
    <Svg viewBox="0 0 15 10" r={props.r} h={props.h}>
        <path d="M14 8.68307L7.5 2.18307L1 8.68307" stroke="currentColor" strokeWidth="1px" fill="none"/>
    </Svg>
);
