import React from 'react';
import styled from "styled-components";
import cloudVideo from "../../assets/video/ciel_intro.webm";
import cloudVideoMp4 from "../../assets/video/ciel_intro_480.mp4";
import cloudPlaceholder from "../../assets/images/ciel_intro.jpg"

const Comp = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -500;
`

const VideoItem = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.3;
  //width: 177.77777778vh; /* 100 * 16 / 9 */
  //min-width: 100%;
  //min-height: 56.25vw; /* 100 * 9 / 16 */
  //position: absolute;
  //left: 50%; /* % of surrounding element */
  //top: 50%;
  //transform: translate(-50%, -50%); /* % of current element */
`

const CatalogueBackground = (props) => {
    return (
        <Comp>
            <VideoItem
                preload="auto"
                autoPlay="autoPlay"
                loop="loop"
                muted
                playsinline
                poster={cloudPlaceholder}
                // style={{display: "none"}}
            >
                <source src={cloudVideo} type='video/webm; codecs="vp9, opus"'/>
                <source src={cloudVideoMp4} type='video/mp4'/>
            </VideoItem>
        </Comp>
    );
};

export default CatalogueBackground;