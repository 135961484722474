import React from 'react';
import styled from "styled-components";
import {GatsbyImage} from "gatsby-plugin-image";
import Text from "../layout/Text";

const Comp = styled.div`
  width: 100%;
  height: 70vh;
  //background-color: palegoldenrod;
  //margin-top: 5rem;
  position: relative;

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    //margin-top: 0rem;
  }
`

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const RibbonContainer = styled.div`
  position: absolute;
  top: 6rem;
  left: 0;
  display: flex;
  flex-direction: column;
`

const Ribbon = styled.div`
  background-color: ${({theme}) => theme.colors.accent};
  color: ${({theme}) => theme.colors.background};
  z-index: 100;
  //padding: 1rem 2rem 1rem ${({theme}) => theme.sizes.globalMargins};
  margin-bottom: 1rem;
  height: 4rem;
  display: flex;
  align-items: center;
  padding-left: ${({theme}) => theme.sizes.globalMargins};
  padding-right: 2rem;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    border: 2em solid ${({theme}) => theme.colors.accent};
    z-index: -1;
    right: -2em;
    border-left-width: 1.5em;
    border-right-color: transparent;
  }

  @media (max-width: ${({theme}) => theme.breakPoints.s}) { // prev m
    padding: ${({theme}) => theme.spacer.medium[1]};
    width: fit-content;
  }
`

const CoverImage = (props) => {

    const ribbons = props.awards.map((item, index) => {
        return (
            <Ribbon key={index}>
                <Text s='standard'>
                    {item.name}
                </Text>
            </Ribbon>
        )
    })

    return (
        <Comp>
            <RibbonContainer>
                {ribbons}
            </RibbonContainer>

            {props.data && props.data.image && props.data.image.asset ?
                <Image alt={props.data.text} image={props.data.image.asset.gatsbyImageData}/> : null}
        </Comp>
    );
};

export default CoverImage;