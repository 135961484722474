import styled from "styled-components";

const GlobalWidth = styled.div`
  width: 100%;
  padding: 0 ${({theme}) => theme.sizes.globalMargins};
  //overflow-x: hidden;
  //background-color: palegoldenrod;
    // max-width: ${({theme}) => theme.sizes.globalWidth};
    // padding-left: ${({theme}) => theme.spacer.small[0]};
    // padding-right: ${({theme}) => theme.spacer.small[0]};
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //background-color: blanchedalmond;

    @media (max-width: ${({theme}) => theme.breakPoints.s}) { // prev m
      padding-left: ${({theme}) => theme.spacer.medium[1]};
      padding-right: ${({theme}) => theme.spacer.medium[1]};
    }
`;

export default GlobalWidth;
