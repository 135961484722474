import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
	width: auto;
	height: 100%;
	color: inherit;
`;

export const Youtube = () => (
	<Svg viewBox="0 0 50 35">
		<path
			fill="currentColor"
			d="M48.96,5.47c-0.58-2.15-2.27-3.85-4.42-4.42C40.64,0,25,0,25,0S9.36,0,5.47,1.04C3.31,1.62,1.62,3.31,1.04,5.47
	C0,9.36,0,17.5,0,17.5s0,8.14,1.04,12.03c0.58,2.15,2.27,3.85,4.42,4.42C9.36,35,25,35,25,35s15.64,0,19.53-1.04
	c2.15-0.58,3.85-2.27,4.42-4.42C50,25.64,50,17.5,50,17.5S50,9.36,48.96,5.47z M20,25V10l12.99,7.5L20,25z"
		/>
	</Svg>
);
