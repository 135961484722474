export function randomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function findNested(obj, key, value) {
    const len = obj ? Object.keys(obj).length : 0
    // Base case
    if (obj[key] === value) {
        return obj;
    } else {
        for (let i = 0; i < len; i++) {
            const item = obj[Object.keys(obj)[i]]
            if (Array.isArray(item)) { //if item is an array
                // console.log(item)
                const len = item.length;
                for (let j = 0; j < len; j++) {
                    const found = findNested(item[j], key, value);
                    if (found) {
                        // If the object was found in the recursive call, bubble it up.
                        return true;
                    }
                }
                // const found = findNested(obj[i], key, value);
                // if (found) {
                // If the object was found in the recursive call, bubble it up.
                // return found;
                // }
            } else if (item && typeof item === "object") { // if item is not an array but is an object
                // console.log(item._id)
                const found = findNested(item, key, value);
                if (found) {
                    // If the object was found in the recursive call, bubble it up.
                    return true;
                }
            }
            // else if (Array.isArray(obj[i])) { // if item is array
            //     const len = obj[i].length;
            //     for (let j = 0; j < len; j++) {
            //         const found = findNested(obj[i][j], key, value);
            //         if (found) {
            //             // If the object was found in the recursive call, bubble it up.
            //             return found;
            //         }
            //     }
            // }
        }
    }
}