import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
	width: auto;
	height: 100%;
	color: inherit;
`;

export const Linkedin = () => (
	<Svg viewBox="0 0 50 50">
		<path
			fill="currentColor"
			d="M45.48,0H4.52C2.02,0,0,2.02,0,4.52v40.95C0,47.97,2.02,50,4.52,50h40.95c2.5,0,4.52-2.03,4.52-4.52V4.52
	C50,2.02,47.97,0,45.48,0z M15.47,43.17c0,0.73-0.59,1.32-1.32,1.32h-5.6c-0.73,0-1.32-0.59-1.32-1.32V19.69
	c0-0.73,0.59-1.32,1.32-1.32h5.6c0.73,0,1.32,0.59,1.32,1.32V43.17z M11.35,16.16c-2.94,0-5.32-2.38-5.32-5.32s2.38-5.32,5.32-5.32
	s5.32,2.38,5.32,5.32S14.29,16.16,11.35,16.16z M44.75,43.28c0,0.67-0.54,1.21-1.21,1.21h-6.01c-0.67,0-1.21-0.54-1.21-1.21V32.26
	c0-1.64,0.48-7.2-4.29-7.2c-3.71,0-4.46,3.8-4.61,5.51v12.71c0,0.67-0.54,1.21-1.21,1.21h-5.81c-0.67,0-1.21-0.54-1.21-1.21v-23.7
	c0-0.67,0.54-1.21,1.21-1.21h5.81c0.67,0,1.21,0.54,1.21,1.21v2.05c1.37-2.06,3.42-3.65,7.76-3.65c9.63,0,9.57,8.99,9.57,13.94
	L44.75,43.28L44.75,43.28z"
		/>
	</Svg>
);
