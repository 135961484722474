import React from 'react';
import styled from "styled-components";
import GlobalWidth from "../layout/GlobalWidth";
import Text from "../layout/Text";
import Spacer from "../layout/Spacer";
import Conditional from "../layout/Conditional";

const Comp = styled(GlobalWidth)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    //background-color: ${({theme}) => theme.colors.background};
`

const Title = styled.div`
  filter: ${({theme}) => theme.filters.titleDropShadow};
`

const PageTitle = (props) => {
    return (
        <Comp>
            <Title>
                <Conditional desktop>
                    <Spacer s="large"/>
                </Conditional>
                <Conditional mobile>
                    <Spacer s="large"/>
                </Conditional>

                <Text s="mediumTitle" a="center">
                    {props.children}
                </Text>
            </Title>
            <Conditional desktop>
                <Spacer s="large"/>
            </Conditional>
        </Comp>
    );
};

export default PageTitle;