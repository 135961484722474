import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
  height: 100%;
  width: auto;
  color: inherit;
`;

export const TriangleArrow = (props) => (
    <Svg viewBox="0 0 14 7" {...props}>
        <path
            d="M7.57637 6.78612C7.25805 7.07129 6.74195 7.0713 6.42363 6.78612L0.240375 1.24661C-0.273116 0.786582 0.0905574 -5.47286e-08 0.816743 0L13.1833 9.31995e-07C13.9094 9.86724e-07 14.2731 0.786582 13.7596 1.24661L7.57637 6.78612Z"
            fill="currentColor"/>
    </Svg>
);