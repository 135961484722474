import React from 'react';
import styled from "styled-components";
import Text from "../layout/Text";
import Spacer from "../layout/Spacer";
import Link from "../localization/Link";

const Comp = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 15rem;
  max-width: 20rem;
  width: 100%;
  padding-bottom: ${({theme}) => theme.spacer.large[0]};
  padding-right: ${({theme}) => theme.spacer.large[0]};
  //background-color: palegoldenrod;

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin-right: 0;
  }
`

const GraduateLink = styled(Link)`
  cursor: pointer;
  text-underline-offset: 0.1em;
  text-decoration-thickness: 1px;

  :hover {
    text-decoration: underline;
  }
`

const LetterSection = (props) => {
    const {data} = props

    const names = data.names.map((item, index) => {
        return <Text s="standard" key={index}>
            <GraduateLink to={`/catalogue#authors=${item._id}`}>
                {item.firstName} {item.lastName}
                <br/>
                -> {item.diploma.name}
                <Spacer s="small"/>
            </GraduateLink>
        </Text>
    })

    return (
        <Comp>
            <Text s="bigTitle">
                {data.title}
            </Text>
            <Spacer s="medium"/>

            {names}
        </Comp>
    );
};

export default LetterSection;