import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
	width: auto;
	height: 100%;
	color: inherit;
`;

export const Instagram = () => (
	<Svg viewBox="0 0 50 50">
		<g>
			<path
				fill="currentColor"
				d="M49.87,14.7c-0.12-2.66-0.55-4.48-1.16-6.07c-0.64-1.68-1.61-3.18-2.89-4.44c-1.25-1.27-2.76-2.26-4.43-2.88
		c-1.59-0.62-3.41-1.05-6.07-1.16C32.65,0.03,31.8,0,25.01,0S17.37,0.03,14.7,0.15c-2.66,0.12-4.48,0.55-6.07,1.16
		C6.96,1.94,5.45,2.92,4.2,4.2C2.93,5.45,1.94,6.97,1.32,8.63c-0.62,1.59-1.05,3.41-1.16,6.07C0.03,17.37,0,18.22,0,25.01
		s0.03,7.64,0.15,10.31c0.12,2.66,0.55,4.48,1.16,6.07c0.64,1.68,1.62,3.18,2.89,4.44c1.25,1.27,2.76,2.26,4.43,2.88
		c1.59,0.62,3.41,1.05,6.07,1.16c2.67,0.12,3.52,0.15,10.31,0.15s7.64-0.03,10.31-0.15c2.66-0.12,4.48-0.55,6.07-1.16
		c3.36-1.3,6.02-3.96,7.32-7.32c0.62-1.59,1.05-3.41,1.16-6.07C49.97,32.65,50,31.8,50,25.01S49.99,17.37,49.87,14.7z M45.37,35.12
		c-0.11,2.44-0.52,3.76-0.86,4.64c-0.84,2.18-2.57,3.91-4.75,4.75c-0.88,0.34-2.21,0.75-4.64,0.86c-2.64,0.12-3.43,0.15-10.1,0.15
		s-7.47-0.03-10.1-0.15c-2.44-0.11-3.76-0.52-4.64-0.86c-1.08-0.4-2.07-1.04-2.87-1.87c-0.83-0.81-1.47-1.79-1.87-2.87
		c-0.34-0.88-0.75-2.21-0.86-4.64c-0.12-2.64-0.15-3.43-0.15-10.1s0.03-7.47,0.15-10.1c0.11-2.44,0.52-3.76,0.86-4.64
		c0.4-1.08,1.04-2.07,1.88-2.87c0.81-0.83,1.79-1.47,2.87-1.87c0.88-0.34,2.21-0.75,4.64-0.86c2.64-0.12,3.43-0.15,10.1-0.15
		c6.68,0,7.47,0.03,10.1,0.15c2.44,0.11,3.76,0.52,4.64,0.86c1.08,0.4,2.07,1.04,2.87,1.87c0.83,0.81,1.47,1.79,1.87,2.87
		c0.34,0.88,0.75,2.21,0.86,4.64c0.12,2.64,0.15,3.43,0.15,10.1S45.49,32.48,45.37,35.12z"
			/>
			<path
				fill="currentColor"
				d="M25.01,12.16c-7.09,0-12.85,5.75-12.85,12.85s5.75,12.85,12.85,12.85c7.09,0,12.85-5.75,12.85-12.85
		S32.1,12.16,25.01,12.16z M25.01,33.34c-4.6,0-8.33-3.73-8.33-8.33s3.73-8.33,8.33-8.33c4.6,0,8.33,3.73,8.33,8.33
		S29.61,33.34,25.01,33.34z"
			/>
			<path
				fill="currentColor"
				d="M41.36,11.65c0,1.66-1.34,3-3,3c-1.66,0-3-1.34-3-3c0-1.66,1.34-3,3-3C40.02,8.66,41.36,10,41.36,11.65z"
			/>
		</g>
	</Svg>
);
