import React, {useRef} from "react";
import YouTube from "react-youtube";
import Vimeo from "@u-wave/react-vimeo";
import * as styles from "./style.module.css";
import getVideoId from "get-video-id";

const Video = (props) => {
    const videoPlayer = useRef();

    const opts = {
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            start: 0,
            autoplay: 0,
            rel: 0,
            modestbranding: 1,
        },
    };

    const video = getVideoId(props.url);
    // console.log(props.url.replace(/showcase\/\d*\/video\//g, ""))
    // console.log(props.url)

    return (
        <>
            {
                props.url && props.url !== "" ? video.service === "youtube" ?
                    <YouTube
                        videoId={video.id}
                        opts={opts}
                        containerClassName={styles.wrapper}
                        ref={videoPlayer}
                    />
                    :
                    <Vimeo
                        video={video.id}
                        width="100%"
                        height="auto"
                        className="vimeo_embed"
                    />
                    : null
            }
        </>
    );
};

export default Video;
