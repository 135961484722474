import React from 'react';
import Text from "../layout/Text";
import DataLink from "./DataLink";

const Instagram = (props) => {
    const {data} = props
    return (
        <DataLink to={`${data.authors[0].socialMedia[0].socialMedia.baseURL}${data.authors[0].socialMedia[0].id}`}
                  target="_blank" rel="noopener noreferrer">
            <Text s="small">
                {data.authors[0].firstName} {data.authors[0].lastName} {props.text} Instagram
            </Text>
        </DataLink>
    );
};

export default Instagram;