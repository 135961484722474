import React from 'react';
import styled from "styled-components";
import GlobalWidth from "../layout/GlobalWidth";
import Block from "../editorial/Block";

const Comp = styled(GlobalWidth)`
`

const Credits = (props) => {
    // const credits = props.data.map((item, index) => {
    //     if (item.identification.id === "title") {
    //         return <Text key={index} s="standard">
    //             {item.content}
    //             <Spacer s="medium"/>
    //         </Text>
    //     } else if (item.identification.id === "text") {
    //         return <PreText key={index} s="small">
    //             {item.content}
    //         </PreText>
    //     }
    // })

    return (
        <Comp>
            <Block data={props.data[0].content}/>
        </Comp>
    );
};

export default Credits;