import {useEffect, useState} from 'react';

const useLoadMore = (projects, projectsPerPage) => {

    // State for the list
    const [list, setList] = useState([...projects.slice(0, projectsPerPage)])

    useEffect(() => {
        setList([...projects.slice(0, projectsPerPage)])
    }, [projects, projectsPerPage])

    // State to trigger oad more
    const [loadMore, setLoadMore] = useState(false)

    // State of whether there is more to load
    const [hasMore, setHasMore] = useState(projects.length > projectsPerPage)

    // Load more button click
    const handleLoadMore = () => {
        setLoadMore(true)

        setTimeout(() => {
            setLoadMore(false)
        }, 500)
    }

    // Handle loading more articles
    useEffect(() => {
        if (loadMore && hasMore) {
            const currentLength = list.length
            const isMore = currentLength < projects.length
            const nextResults = isMore
                ? projects.slice(currentLength, currentLength + projectsPerPage)
                : []
            setList([...list, ...nextResults])
            setLoadMore(false)
        }
    }, [loadMore, hasMore, projectsPerPage]) //eslint-disable-line

    //Check if there is more
    useEffect(() => {
        const isMore = list.length < projects.length
        setHasMore(isMore)
    }, [list]) //eslint-disable-line

    return [list, hasMore, handleLoadMore]
};

export default useLoadMore;