import React, {useState} from 'react';
import styled from "styled-components";
import GlobalWidth from "../layout/GlobalWidth";
import BigTitle from "../UI/BigTitle";
import Spacer from "../layout/Spacer";
import VisitCard from "./VisitCard";
import StandardButton from "../UI/StandardButton";
import Conditional from "../layout/Conditional";

const Comp = styled(GlobalWidth)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    flex-direction: column;
    align-items: center;
  }
`

const Visits = (props) => {

    const [selected, setSelected] = useState(0);

    function handleSelect(index) {
        setSelected(index)
    }

    const buttons = props.links.map((item, index) => {
        return <React.Fragment key={index}>
            <StandardButton onClick={() => handleSelect(index)}>
                {item.text}
            </StandardButton>

            <Conditional desktop>
                <Spacer s="medium"/>
            </Conditional>
            <Conditional mobile>
                <Spacer s="small"/>
            </Conditional>
        </React.Fragment>
    })

    return (
        <Comp>
            <BigTitle>
                {props.data.find(item => item.identification.id === "title").content}
            </BigTitle>
            <Conditional desktop>
                <Spacer s="large"/>
            </Conditional>
            <Conditional mobile>
                <Spacer s="medium"/>
            </Conditional>

            <VisitCard data={props.links[selected]}/>

            <Conditional desktop>
                <Spacer s="large"/>
            </Conditional>
            <Conditional mobile>
                <Spacer s="medium"/>
            </Conditional>

            <ButtonsContainer>
                {buttons}
            </ButtonsContainer>
        </Comp>
    );
};

export default Visits;