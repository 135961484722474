import React from 'react';
import styled from "styled-components";
import SocialMediaItem from "./SocialMediaItem";
import {useLanguageContext} from "../../../contexts/LanguageContext";
import Spacer from "../Spacer";

const Comp = styled.div`
  display: flex;
`

const SocialMedia = (props) => {
    const language = useLanguageContext()

    return (
        <Comp>
            <SocialMediaItem
                text={props.facebook.text[language.lang]}
                link={props.facebook.link[language.lang]}
                media="facebook"
            />
            <Spacer s="medium"/>

            <SocialMediaItem
                text={props.twitter.text[language.lang]}
                link={props.twitter.link[language.lang]}
                media="twitter"
            />
            <Spacer s="medium"/>

            <SocialMediaItem
                text={props.instagram.text[language.lang]}
                link={props.instagram.link[language.lang]}
                media="instagram"
            />
            <Spacer s="medium"/>

            <SocialMediaItem
                text={props.youtube.text[language.lang]}
                link={props.youtube.link[language.lang]}
                media="youtube"
            />
            <Spacer s="medium"/>

            <SocialMediaItem
                text={props.linkedin.text[language.lang]}
                link={props.linkedin.link[language.lang]}
                media="linkedin"
            />
        </Comp>
    );
};

export default SocialMedia;