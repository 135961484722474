import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
  width: auto;
  height: 100%;
  color: inherit;
  //mix-blend-mode: difference;
  //background-color: red;
`;

export const BurgerIcon = (props) => (
    <Svg viewBox="0 0 33 24" onClick={props.onClick}>
        <rect width="33" height="4.8" rx="2.4" fill="currentColor"/>
        <rect y="9.59998" width="33" height="4.8" rx="2.4" fill="currentColor"/>
        <rect y="19.2" width="33" height="4.8" rx="2.4" fill="currentColor"/>
    </Svg>
);
