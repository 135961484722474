import styled from "styled-components";
import React from 'react';
import Spacer from "../layout/Spacer";
import {SelectArrow} from "../icons/SelectArrow";
import Link from "../localization/Link";

const Comp = styled(Link)`
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: ${p => p.indent ? "2.19rem" : null};
  cursor: pointer;
  text-underline-offset: 0.1em;
  text-decoration-thickness: 1px;

  :hover {
    text-decoration: underline;
  }
`

const DataLink = (props) => {
    return (
        <Comp to={props.to} indent={props.indent} target="_blank" rel="noopener noreferrer">
            {props.children}
            <Spacer s='smaller'/>
            <SelectArrow r="90deg"/>
        </Comp>
    );
};

export default DataLink;