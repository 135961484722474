import React from 'react';
import styled from "styled-components";
import video from "../../assets/video/LightClouds.webm"
import mp4video from "../../assets/video/LightClouds_480.mp4"
import videoPlaceholder from "../../assets/images/LightClouds.jpg"

const Comp = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 300vh;
  //background-color: rgba(255, 0, 0, 0.55);
  z-index: -200;
`

const VideoItem = styled.video`
  height: 100%;
  width: 100%;
  object-fit: cover;
  //position: absolute;
  bottom: 0;
  //width: 177.77777778vh; /* 100 * 16 / 9 */
  //min-width: 100%;
  //min-height: 56.25vw; /* 100 * 9 / 16 */
  //position: absolute;
  //left: 50%; /* % of surrounding element */
  //top: 50%;
  //transform: translate(-50%, -50%); /* % of current element */
`

const TopGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(248, 249, 250, 0) 0%, rgba(248, 249, 250, 0.1) 15%, rgba(248, 249, 250, 0.225806) 34.9%, rgba(248, 249, 250, 1) 100%);
  z-index: 1;
`

const FooterVideo = (props) => {
    return (
        <Comp>
            <TopGradient/>

            <VideoItem
                preload="auto"
                autoPlay="autoPlay"
                loop="loop"
                muted
                playsInline
                poster={videoPlaceholder}
                // style={{display: "none"}}
            >
                <source src={video} type='video/webm; codecs="vp9, opus"'/>
                <source src={mp4video} type='video/mp4'/>
            </VideoItem>
        </Comp>
    );
};

export default FooterVideo;