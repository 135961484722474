import styled from "styled-components";

function getHeight({ theme, axis, size, s }, index) {
	if (size || s) {
		return axis === "horizontal" || axis === "h" ? "1px" : theme.spacer[s ? s : size][index];
	} else {
		return theme.spacer["medium"][index];
	}
}

function getWidth({ theme, axis, size, s }, index) {
	if (size || s) {
		return axis === "vertical" || axis === "v" ? "1px" : theme.spacer[s ? s : size][index];
	} else {
		return theme.spacer["medium"][index];
	}
}

// smaller
// small
// medium
// large
// larger

const Spacer = styled.span`
	// width: ${p => p.theme.spacer[p.size]};
	// height: ${p => p.theme.spacer[p.size]};
	display: block;
	width: ${props => getWidth(props, 0)};
	min-width: ${props => getWidth(props, 0)};
	height: ${props => getHeight(props, 0)};
	min-height: ${props => getHeight(props, 0)};
	
	// @media (max-width: ${({ theme }) => theme.breakPoints.s}) {
	// 	text-align: left;
	// 	width: ${props => getWidth(props, 1)};
	// 	min-width: ${props => getWidth(props, 1)};
	// 	height: ${props => getHeight(props, 1)};
	// 	min-height: ${props => getHeight(props, 1)};
	// }
`;

export default Spacer;
