import React from 'react';
import styled from "styled-components";
import cloudVideo from "../../assets/video/ciel_intro.webm";
import cloudVideoMp4 from "../../assets/video/ciel_intro_480.mp4";
import cloudPlaceholder from "../../assets/images/ciel_intro.jpg"

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`

const BottomGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  z-index: 5;

  background: linear-gradient(180deg, rgba(248, 249, 250, 0) 0%, rgba(248, 249, 250, 0.1) 15%, rgba(248, 249, 250, 0.225806) 34.9%, rgba(248, 249, 250, 1) 100%);
`

const BigText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12rem;
  color: ${({theme}) => theme.colors.lemon};
  position: relative;
  z-index: 5;
  text-transform: uppercase;
  line-break: anywhere;
  text-align: center;
  line-height: 90%;

  @media (max-width: ${({theme}) => theme.breakPoints.m}) {
    font-size: 20vw;
  }

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    font-size: 20vw;
  }
`

const VideoItem = styled.video`
  height: 100%;
  width: 177.77777778vh; /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 56.25vw; /* 100 * 9 / 16 */
  position: absolute;
  left: 50%; /* % of surrounding element */
  top: 50%;
  transform: translate(-50%, -50%); /* % of current element */
`

const BackgroundVideo = (props) => {
    return (
        <Container>
            <BottomGradient/>
            <BigText>
                #{props.tag ? props.tag.name : null}
            </BigText>

            <VideoItem
                preload="auto"
                autoPlay="autoPlay"
                loop="loop"
                muted
                playsInline
                poster={cloudPlaceholder}
                // style={{display: "none"}}
            >
                <source src={cloudVideo} type='video/webm; codecs="vp9, opus"'/>
                <source src={cloudVideoMp4} type='video/mp4'/>
            </VideoItem>
        </Container>
    );
};

export default BackgroundVideo;