import React from 'react';
import styled from "styled-components";
import FilterSelect from "./FilterSelect";
import Spacer from "../layout/Spacer";
import GlobalWidth from "../layout/GlobalWidth";
import FiltersSummary from "./FiltersSummary";

const Comp = styled(GlobalWidth)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  //justify-content: center;
  flex-direction: column;
  z-index: 200;
  overflow-y: scroll;
    background-color: ${({theme}) => theme.colors.background};
  //background-image: linear-gradient(rgba(248, 249, 250, 0.5) 0%, rgba(248, 249, 250, 1) 20%, rgba(248, 249, 250, 1) 80%, rgba(248, 249, 250, 0.5) 100%);
  //background-color: palegoldenrod;
`

const CloseButton = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: ${({theme}) => theme.colors.background};
  border: 1px solid ${({theme}) => theme.colors.accent};
  height: 2rem;
  color: ${({theme}) => theme.colors.accent};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
  text-transform: uppercase;
`

const CloseSpacer = styled.div`
  height: 80rem;
  width: 100%;
`

const FiltersContainer = styled.div`
  //height: 50vh;
  //overflow: scroll;
  width: 100%;
`

const MobileFilters = (props) => {
    const {data} = props

    const authors = data.find(i => i.identification.id === "authors").content
    const fields = data.find(i => i.identification.id === "fields").content
    const diplomas = data.find(i => i.identification.id === "diplomas").content
    const options = data.find(i => i.identification.id === "options").content
    const tutors = data.find(i => i.identification.id === "tutors").content
    const media = data.find(i => i.identification.id === "media").content
    const awards = data.find(i => i.identification.id === "awards").content
    const tags = data.find(i => i.identification.id === "tags").content

    function close() {
        props.set(false)
    }

    return (
        <Comp>
            <CloseButton onClick={close}>
                Fermer
            </CloseButton>

            <Spacer s='small'/>

            <FiltersContainer>
                <FilterSelect
                    title={authors}
                    id={"authors"}
                />
                <Spacer s='small'/>

                <FilterSelect
                    title={fields}
                    id={"fields"}
                />
                <Spacer s='small'/>

                <FilterSelect
                    title={diplomas}
                    id={"diplomas"}
                />
                <Spacer s='small'/>

                <FilterSelect
                    title={options}
                    id={"options"}
                />
                <Spacer s='small'/>

                <FilterSelect
                    title={tutors}
                    id={"tutors"}
                />
                <Spacer s='small'/>

                <FilterSelect
                    title={awards}
                    id={"awards"}
                />
                <Spacer s='small'/>

                <FilterSelect
                    title={media}
                    id={"media"}
                />
                <Spacer s='small'/>

                <FilterSelect
                    title={tags}
                    id={"tags"}
                />
                <Spacer s='small'/>
            </FiltersContainer>

            <FiltersSummary data={props.summary} menu/>
            <Spacer s='medium'/>
            <CloseSpacer/>
        </Comp>
    );
};

export default MobileFilters;