import React from 'react';
import styled from "styled-components";
import Text from "../layout/Text";
import {Eye} from "../icons/Eye";

const Comp = styled.div`
  border-radius: ${({theme}) => theme.radii.standard};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const DropZone = (props) => {
    return (
        <Comp hover={props.hover}>
            <Text s="dropZone">
                {props.hover && props.dragging ? <Eye/> : props.text}
            </Text>
        </Comp>
    );
};

export default DropZone;