import {useEffect, useState} from 'react';
import Fuse from "fuse.js"

const useFilters = (projects, filters) => {

    const [filteredProjects, setFilteredProjects] = useState([]);

    useEffect(() => {
        // Fuse configuration
        const fuseOptions = {
            threshold: 0,
            keys: [
                "_id",
                'authors._id',
                'tutors._id',
                "authors.diploma._id",
                "authors.diploma.field._id",
                "authors.option._id",
                "media._id",
                "awards._id",
                "tags._id"
            ]
        }
        // generate id array from hash
        const query = {
            $and:
                filters ? Object.keys(filters).map(item => {
                        if (filters[item].length > 0) {
                            return {
                                $or: filters[item].map(item => {
                                    return {[item.path]: item.value}
                                })
                            }
                        } else return []
                    }
                ).flat() : []
        }
        // console.log(query)

        const fuse = new Fuse(projects, fuseOptions);
        let temp = fuse.search(query)

        temp = temp.map(item => item.item)

        setFilteredProjects(temp)
    }, [projects, filters])

    return filteredProjects
};

export default useFilters;