import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import StandardButton from "../UI/StandardButton";
import Project from "./Project";
import GlobalWidth from "../layout/GlobalWidth";
import Spacer from "../layout/Spacer";
import {useProjectContext} from "../../contexts/ProjectsContext";
import useLoadMore from "../../hooks/useLoadMore";
import Conditional from "../layout/Conditional";
import useOnScreen from "../../hooks/useOnScreen";

const Comp = styled(GlobalWidth)`
  display: flex;
  flex-direction: column;
`

const ProjectsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    flex-direction: column;
    align-items: center;
  }
`

const LoadMoreContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Projects = (props) => {
    const projects = useProjectContext()
    const onceRef = useRef(false)

    // set hash
    useEffect(() => {
        if (!onceRef.current) {
            projects.setHash(props.hash)
            onceRef.current = true
        }
    }, [props.hash, projects])

    // load all projects into project context
    useEffect(() => {
        projects.setAllProjects(props.projects)
    }, [projects, props.projects])

    const [list, hasMore, handleLoadMore] = useLoadMore(projects.projects, 12)

    const displayProjects = list ? list.map((item, index) => {
        return (
            <Project data={item} key={index} index={index}/>
        )
    }) : null

    useEffect(() => {
        projects.displayedProjects(list.length)
    }, [list, projects])

    const loadRef = useRef()
    const bottomReached = useOnScreen(loadRef)
    // console.log(bottomReached)

    useEffect(() => {
        if (bottomReached) {
            handleLoadMore()
        }
    }, [bottomReached, handleLoadMore])

    return (
        <Comp>

            <ProjectsContainer>
                {displayProjects}
                <br/>
            </ProjectsContainer>

            <Conditional desktop>
                <Spacer s="medium"/>
            </Conditional>

            {
                hasMore ?
                    <LoadMoreContainer>
                        <StandardButton onClick={handleLoadMore}>
                            {props.loadMore}
                        </StandardButton>
                    </LoadMoreContainer>
                    : null
            }
            <div ref={loadRef}/>
        </Comp>
    );
};

export default Projects;