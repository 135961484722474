import React, {useState} from 'react';
import styled from "styled-components";
import GlobalWidth from "../layout/GlobalWidth";
import {useProjectContext} from "../../contexts/ProjectsContext";
import Spacer from "../layout/Spacer";
import FilterTag from "./FilterTag";
import ProjectsNumber from "./ProjectsNumber";
import RemoveFilters from "./RemoveFilters";
import Conditional from "../layout/Conditional";
import SmallButton from "../UI/SmallButton";

const Comp = styled(GlobalWidth)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: -0.5rem;
  padding-top: 1rem;
  padding-bottom: 0;
  //justify-content: center;
  z-index: 50;
  //backdrop-filter: blur(50px);
  //background-color: palegoldenrod;

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    // prev m
    top: -0.25rem;
    //flex-direction: row;
    //width: 100%;
    //margin: 0;
    //padding-left: 0;
    //padding-right: 0;
      //align-items: ${p => p.menu ? "center" : "flex-end"};
    //transform: translateY(-110%);
  }
`

const MobileComp = styled.div`
    position: relative;
  z-index: -5;
`

const BlurBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -500;
  background-color: ${({theme}) => theme.colors.background};
  //background: linear-gradient(0deg, rgba(248, 249, 250, 0) 0%, rgba(248, 249, 250, 0.8) 50%, rgba(248, 249, 250, 0) 100%);
  filter: blur(25px);
  //backdrop-filter: blur(10px);
`

const TagsContainer = styled.div`
  display: flex;
  //flex-direction: column;
  width: 100%!important;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`

const Tag = styled.div`
  display: flex;
  //position: absolute;
  z-index: 200;
  flex-wrap: wrap;

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    margin-right: ${({theme}) => theme.spacer.small[0]};
    flex-direction: column;
  }
`

const NumberContainer = styled.div`
  display: flex;
  align-items: flex-end;
  transition: all 0.4s;

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    // prev m
    flex-direction: column;
    align-self: ${p => p.sticked && !p.menu ? "flex-end" : null};
  }
`

const MobileFiltersButton = styled(SmallButton)`
  //align-self: center;
`

const ButtonsContainer = styled.div`
  display: flex;
  //background-color: palegoldenrod;
  justify-content: ${p => p.menu ? "flex-start" : "center"};
  width: 100%;
  flex-direction: ${p => p.menu ? "column" : "row"};
`

const FiltersSummary = (props) => {
    const {data} = props
    const projects = useProjectContext()

    const projectString = data.find(i => i.identification.id === "projects").content
    const removeString = data.find(i => i.identification.id === "remove").content
    const filtersButton = data.find(i => i.identification.id === "filtersbutton").content

    const [sticked, setSticked] = useState(false)

    function trackStick() {
        const scrolled = document.documentElement.scrollTop;
        setSticked(scrolled > 155)
    }

    if (typeof window !== "undefined") {
        window.addEventListener('scroll', trackStick);
    }

    function setFilters() {
        props.setMobileFilters(true)
        window.scrollTo(0, 0)
    }

    const displayFilters = Object.keys(projects.filters).map((key, index) => {
            return (
                projects.filters[key] && projects.filters[key].length > 0 ?
                    <Tag key={index}>
                        {/*<Text s='standard'>*/}
                        {/*    {key}*/}
                        {/*</Text>*/}
                        {/*<Spacer s="small"/>*/}

                        {
                            projects.filters[key].map((item, index) => {
                                return (
                                    <FilterTag
                                        key={index}
                                        id={key}
                                        filter={item}
                                        menu={props.menu}
                                    />
                                )
                            })
                        }
                    </Tag>
                    : null
            )
        }
    )

    const ConditionalContainer = props.menu ? MobileComp : Comp;

    return (
        <ConditionalContainer menu={props.menu}>
            <NumberContainer sticked={sticked} menu={props.menu}>
                <ProjectsNumber
                    displayed={projects.projects.length}
                    number={projects.allProjects.length}
                    text={projectString}
                    menu={props.menu}
                />
                <Conditional mobile>
                    <Spacer s="small"/>
                </Conditional>

            </NumberContainer>

            {/*<Conditional mobile>*/}
            {/*    <MobileFiltersButton s="small">*/}
            {/*        Filtrer les projets*/}
            {/*    </MobileFiltersButton>*/}
            {/*</Conditional>*/}

            <BlurBackground/>

            {/*<Conditional mobile>*/}
            <TagsContainer menu={props.menu}>
                {/*{props.menu ? displayFilters : null}*/}
                {displayFilters}
            </TagsContainer>
            <Spacer s="smaller"/>
            {/*</Conditional>*/}

            {/*<Conditional desktop>*/}
            {/*    <Spacer s="small"/>*/}
            {/*    <TagsContainer>*/}
            {/*        {displayFilters}*/}
            {/*        <Conditional desktop>*/}
            {/*            <Spacer s="small"/>*/}
            {/*            <RemoveFilters text={removeString}/>*/}
            {/*        </Conditional>*/}
            {/*    </TagsContainer>*/}
            {/*</Conditional>*/}
            {/*</Conditional>*/}

            <ButtonsContainer menu={props.menu}>
                <Conditional mobile>
                    {!props.menu ? <>
                        <MobileFiltersButton
                            s="small"
                            onClick={setFilters}
                        >
                            {filtersButton}
                        </MobileFiltersButton>
                    </> : null}

                    {projects.filtersOn ?
                        <div style={{flex: 1}}/>
                        : null}
                </Conditional>
                <RemoveFilters
                    text={removeString}
                />
            </ButtonsContainer>
            <Spacer s="small"/>
        </ConditionalContainer>
    );
};

export default FiltersSummary;