import React, { createContext, useContext, useState } from "react";

export const LanguageContext = createContext();

// We wrap the provider in a nice little component
// which will hold the state and provide methods to
// update the state
function LanguageProvider(props) {
	const [language, setLang] = useState(null);

	const lang = !language ? "fr" : language;

	const languageData = { lang, setLang };

	return <LanguageContext.Provider value={languageData} {...props} />;
}

// Here we create a custom hook that allows us to consume
// the context
function useLanguageContext() {
	return useContext(LanguageContext);
}

export { LanguageProvider, useLanguageContext };
