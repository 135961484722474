import React from 'react';
import styled from "styled-components";
import BigTitle from "../UI/BigTitle";
import Spacer from "../layout/Spacer";
import FieldCard from "../UI/FieldCard";
import GlobalWidth from "../layout/GlobalWidth";

const Comp = styled(GlobalWidth)`
  display: flex;
  flex-direction: column;
  color: ${({theme}) => theme.colors.accent};
  width: 100%;
  align-items: center;
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({theme}) => theme.colors.accent};
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: ${({theme}) => theme.breakPoints.xs}) {
    flex-direction: column;
    width: 100%;
  }
`

const Diploma = (props) => {

    const cards = props.cards.map((item, index) => {
        return (
            <FieldCard
                title={item.title}
                image={item.image}
                link={item.link}
                index={index}
                key={index}
            />
        )
    })

    return (
        <Comp>
            <BigTitle>
                {props.title}
            </BigTitle>
            <Spacer s="large"/>

            <CardContainer>
                {cards}
            </CardContainer>
        </Comp>
    );
};

export default Diploma;