import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
	width: auto;
	height: 100%;
	color: inherit;
`;

export const Twitter = () => (
	<Svg viewBox="0 0 50 40.63">
		<path
			fill="currentColor"
			d="M15.72,40.63c18.87,0,29.19-15.63,29.19-29.19c0-0.44,0-0.89-0.03-1.33C46.89,8.66,48.62,6.86,50,4.8
	c-1.87,0.83-3.86,1.37-5.89,1.61c2.14-1.28,3.74-3.3,4.51-5.67C46.6,1.94,44.4,2.78,42.1,3.23C38.22-0.9,31.72-1.1,27.59,2.79
	c-2.66,2.51-3.79,6.24-2.97,9.8C16.37,12.18,8.69,8.28,3.48,1.87C0.76,6.56,2.15,12.56,6.66,15.57C5.02,15.52,3.43,15.08,2,14.28
	c0,0.04,0,0.09,0,0.13c0,4.88,3.44,9.09,8.23,10.06c-1.51,0.41-3.09,0.47-4.63,0.18c1.34,4.18,5.2,7.04,9.58,7.12
	c-3.63,2.85-8.12,4.4-12.74,4.4c-0.82,0-1.63-0.05-2.44-0.15C4.69,39.03,10.15,40.63,15.72,40.63"
		/>
	</Svg>
);
