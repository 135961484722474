import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
  width: auto;
  height: 2rem;
  color: inherit;
`;

export const UpArrow = (props) => (
    <Svg viewBox="0 0 32 53" focus={props.focus}>
        <path d="M0.5 16L16 0.5L31.5 16" fill="none" vectorEffect="non-scaling-stroke" strokeWidth="1px"
              stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16 1V52.5" fill="none" vectorEffect="non-scaling-stroke" strokeWidth="1px" stroke="currentColor"
              strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
);
