import styled from "styled-components";
import Text from "../layout/Text";

const SmallButton = styled(Text)`
  text-transform: uppercase;
  border: 1px solid ${({theme}) => theme.colors.accent};
  padding: 0 ${({theme}) => theme.spacer.small[0]};
  cursor: pointer;
  text-align: center;
  transition: all 0.4s;

  :hover {
    background-color: ${({theme}) => theme.colors.accent};
    color: ${({theme}) => theme.colors.background}
  }
`

export default SmallButton