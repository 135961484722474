import React from 'react';
import styled, {useTheme} from "styled-components";
import Select, {components} from 'react-select'
import Text from "../layout/Text";
import {SelectArrow} from "../icons/SelectArrow";
import {Search} from "../icons/Search";
import {useProjectContext} from "../../contexts/ProjectsContext";
import Spacer from "../layout/Spacer";
import Conditional from "../layout/Conditional";

const Comp = styled.div`
  //width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${({theme}) => theme.spacer.medium[0]};

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    // prev m
    margin-bottom: 0;
    width: 100%;
  }
`

const Number = styled(Text)`
  background-color: ${({theme}) => theme.colors.accent};
  color: ${({theme}) => theme.colors.background};
  padding-left: ${({theme}) => theme.spacer.small[0]};
  padding-right: ${({theme}) => theme.spacer.small[0]};
  height: 100%;
  min-height: 38px;
    border: 1px solid ${({theme}) => theme.colors.accent};
  display: flex;
  justify-content: center;
  align-items: center;
`

const DropDown = styled(Select)`
  width: 250px;

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    width: 100%;
  }
`

const PlaceholderContainer = styled.div`
  opacity: ${p => p.focus ? 0.2 : 1};
`

const SelectInput = styled(components.Input)`
  // background-color: palegoldenrod;
  //
  // ::placeholder {
    //   color: ${({theme}) => theme.colors.accent} !important;
  //   opacity: 1 !important;
  // }
`

// custom dropdown indicator
const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            {props.isFocused ? <Search/> : <SelectArrow r="180deg" focus={props.isFocused}/>}
        </components.DropdownIndicator>
    );
}

const FilterSelect = (props) => {
    const theme = useTheme();
    // const [focused, setFocused] = useState(false);

    const {title} = props;

    const projects = useProjectContext();

    // function focus() {
    //     setFocused(true);
    // }
    //
    // function unFocus() {
    //     setFocused(false);
    // }

    // custom placeholder
    const Placeholder = props => {
        return (
            <components.Placeholder {...props}>
                <PlaceholderContainer focus={props.isFocused}>
                    {props.isFocused ? "-> " : null}{props.children}
                </PlaceholderContainer>
            </components.Placeholder>
        );
    };

    // custom ValueContainer
    const ValueContainer = ({children, ...props}) => {

        return (
            <components.ValueContainer {...props}>
                <PlaceholderContainer focus={props.isFocused}>
                    {children}
                </PlaceholderContainer>
            </components.ValueContainer>
        );
    };

    // custom Input
    const Input = ({children, ...props}) => {
        // console.log(props)
        return (
            <SelectInput
                {...props}
                placeholder={props.hasValue ? "-> " + title : null}
            >
                {children}
            </SelectInput>
        );
    };
    // const ValueContainer = ({children, ...props}) => {
    //     return (
    //         <components.ValueContainer {...props}>
    //             <components.Placeholder {...props} isFocused={!props.isFocused}>
    //                 {props.selectProps.placeholder}
    //             </components.Placeholder>
    //             {React.Children.map(children, child =>
    //                 child
    //             )}
    //         </components.ValueContainer>
    //     );
    // };

    const emptyStyles = {
        clearIndicator: (provided, state) => ({
            ...provided,
            display: "none"
        }),
        container: (provided, state) => ({
            ...provided,
            outline: "none",
            // width: "100%",
        }),
        control: (provided, state) => ({
            ...provided,
            outline: "none",
            border: `1px solid ${theme.colors.accent}`,
            borderRadius: 0,
            fontSize: "1.25rem",
            fontFamily: theme.text.buttonSmall.family,
            cursor: "pointer",
            backgroundColor: theme.colors.background,
            boxShadow: 'none',
            boxSizing: "border-box",

            "&:hover": {
                border: `1px solid ${theme.colors.accent}`,
            },
            // justifyContent: "flex-start"
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: theme.colors.accent
        }),
        group: (provided, state) => ({
            ...provided,
        }),
        groupHeading: (provided, state) => ({
            ...provided,
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            color: theme.colors.accent
        }),
        indicatorContainer: (provided, state) => ({
            ...provided,
            display: "none"
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: "none"
        }),
        input: (provided, state) => ({
            ...provided,
            fontSize: "1.25rem",
            fontFamily: theme.text.buttonSmall.family,
            color: theme.colors.accent,
            opacity: state.isFocused ? 0.5 : 1,
            "input::placeholder": {
                color: theme.colors.accent,
                // display: state.isFocused ? "none" : "auto"
            }
        }),
        loadingIndicator: (provided, state) => ({
            ...provided,
        }),
        loadingMessage: (provided, state) => ({
            ...provided,
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            margin: 0,
            boxShadow: "none",
            border: `1px solid ${theme.colors.accent}`,
            transform: "translateY(-1px)",
            backgroundColor: theme.colors.background,
            // position: "relative",
            zIndex: 100
        }),
        menuList: (provided, state) => ({
            ...provided,
            padding: 0,
            fontSize: "1.25rem",
            fontFamily: theme.text.buttonSmall.family,
            color: theme.colors.accent
        }),
        menuPortal: (provided, state) => ({
            ...provided,
        }),
        multiValue: (provided, state) => ({
            ...provided,
            display: "none"
        }),
        multiValueLabel: (provided, state) => ({
            ...provided,
        }),
        multiValueRemove: (provided, state) => ({
            ...provided
        }),
        noOptionsMessage: (provided, state) => ({
            ...provided,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? theme.colors.accent : theme.colors.background,
            color: state.isSelected ? theme.colors.background : theme.colors.accent,
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
            "&:hover": {
                backgroundColor: state.isSelected ? theme.colors.accent : theme.colors.accent,
                color: theme.colors.background
            },
        }),
        placeholder: (provided, state) => ({
            ...provided,
            fontSize: "1.25rem",
            fontFamily: theme.text.buttonSmall.family,
            color: theme.colors.accent,
            // display: "none"
            // backgroundColor: "palegoldenrod"
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontSize: "1.25rem",
            fontFamily: theme.text.buttonSmall.family,
            color: theme.colors.accent
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: "0 0.5rem"
        }),
    };

    function handleChange(selectedOptions) {
        projects.addFilters(selectedOptions, props.id);
    }

    // const options = genFilterOptions(projects.filters[props.id] && projects.filters[props.id].length > 0 ? projects.allProjects : projects.projects, props.id)
    const options = projects.filterOptions[props.id]

    const number = options ? options.length - (projects.filters[props.id] ? projects.filters[props.id].length : 0) : 0

    return (
        <Comp>
            <Number s="buttonSmall">
                {number > 0 ? number : "-"}
            </Number>
            <DropDown
                styles={emptyStyles}
                options={options}
                isMulti
                // menuIsOpen
                placeholder={title}
                components={{DropdownIndicator, ValueContainer, Input, Placeholder}}
                // onMenuOpen={focus}
                // onMenuClose={unFocus}
                onChange={handleChange}
                isClearable={false}
                value={projects.filters[props.id]}
            />
            <Conditional desktop>
                <Spacer s="medium"/>
            </Conditional>
        </Comp>
    );
};

export default FilterSelect;