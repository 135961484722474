import styled from "styled-components";
import {animated, useSpring} from "react-spring";
// import useWindowSize from "../../hooks/useWindowSize";
import {useDrag} from "react-use-gesture";
import React, {useEffect, useRef, useState} from "react";
import {randomInt} from "../../lib/utils";

const DragDiv = styled(animated.div)`
  //background-color: palegoldenrod;
  width: ${({theme}) => theme.cards.standardSize};
  height: ${({theme}) => theme.cards.standardSize};
  color: ${({theme}) => theme.colors.background};
  position: absolute;
  top: calc(${p => p.y}% - (${({theme}) => theme.cards.standardSize} / 2));
  left: calc(${p => p.x}% - (${({theme}) => theme.cards.standardSize} / 2));
    // transform: translateY(-${({theme}) => theme.cards.standardSize}) translateX(-${({theme}) => theme.cards.standardSize});
  transition: width 0.1s, height 0.1s;
    //transform: rotate(${p => p.r}deg)!important;
  z-index: ${p => p.top ? 2 : 0};

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    width: ${({theme}) => theme.cards.smallestSize};
    height: ${({theme}) => theme.cards.smallestSize};
    top: calc(${p => p.y}% - (${({theme}) => theme.cards.smallSize} / 2));
    left: calc(${p => p.x}% - (${({theme}) => theme.cards.smallSize} / 2));
  }
`

const PullRelease = (props) => {
    const {setDrag, index} = props;

    const onceRef = useRef(false)
    const [startPos, setStartPos] = useState([0, 0])

    useEffect(() => {
        if (!onceRef.current) {
            setStartPos([randomInt(30, 90), randomInt(20, 70)])
            onceRef.current = true
        }
    }, [])

    const [{x, y, width, height}, api] = useSpring(() => ({x: 0, y: 0}))
    // const windowSize = useWindowSize();

    // Set the drag hook and define component movement based on gesture data
    // const bind = useDrag(({down, movement: [mx, my]}) => {
    //     api.start({
    //         // width: down ? mx : 500,
    //         width: 500,
    //         height: down ? my : 100,
    //     })
    // })

    const bind = useDrag(({down, offset: [ox, oy], dragging}) => {
            setDrag(index)
            api.start({
                x: ox,
                y: oy,
                // width: theme.cards.standardSize,
                // height: theme.cards.standardSize,
                immediate: down
            })
            props.setDragging(dragging)
        }
        , {
            // bounds: {
            //     left: (-windowSize.width / 2) + 400,
            //     right: (windowSize.width / 2) - 400,
            //     top: (-windowSize.height / 2) + 200,
            //     bottom: (windowSize.height / 2) - 200
            // },
            rubberband: true,
        })

    // console.log(dragging)

    return (
        <DragDiv
            x={startPos[0]}
            y={startPos[1]}
            r={randomInt(-5, 5)}
            top={index === props.dragItem ? 1 : 0} {...bind()}
            style={{x, y, width, height, touchAction: 'none'}}
        >
            {props.children}
        </DragDiv>
    );
};

export default PullRelease;