import React from 'react';
import styled from "styled-components";
import Text from "../layout/Text";
import DataLink from "./DataLink";
import Spacer from "../layout/Spacer";

const Comp = styled.div`

`

const ProjectLinks = (props) => {
        return (
            <>
                {
                    props.socialMedia || props.url ?
                        <Comp>
                            {props.url ?
                                <>
                                    <DataLink to={props.url}>
                                        <Text s="small">
                                            {props.urlText}
                                        </Text>
                                    </DataLink>
                                    <Spacer s="medium"/>
                                </>
                                : null}

                            {props.socialMedia ?
                                <>
                                    <DataLink to={props.socialMedia}>
                                        <Text s="small">
                                            {props.socialMediaText}
                                        </Text>
                                    </DataLink>
                                    <Spacer s="medium"/>
                                </>
                                : null}
                        </Comp>
                        : null
                }
            </>
        );
    }
;

export default ProjectLinks;