import React, {useState} from 'react';
import styled from "styled-components";
import Burger from "./Burger";
import GlobalWidth from "./GlobalWidth";
import HeaderLogo from "./HeaderLogo";
import MainMenu from "./MainMenu";
import SelectYear from "../UI/SelectYear";

const Comp = styled(GlobalWidth)`
  display: flex;
  position: fixed;
  z-index: 200;
  justify-content: space-between;
  //background-color: palegoldenrod;
  //background-image: linear-gradient(rgba(248, 249, 250, 1) 0%, rgba(248, 249, 250, 0.4) 70%, rgba(248, 249, 250, 0) 100%);
  height: ${({theme}) => theme.sizes.headerHeight};
  align-items: center;

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    // prev m
    height: ${({theme}) => theme.sizes.headerHeightMobile};
  }
`

// const Background = styled.div`
//   width: 150%;
//   height: 100%;
//   background-color: ${({theme}) => theme.colors.background};
//   position: absolute;
//   top: -2px;
//   left: 0;
//   transform: translateX(-25%);
//   z-index: -10;
//   filter: blur(10px);
// `

const Header = (props) => {
    const [mainMenu, setMainMenu] = useState(false)

    function toggle() {
        setMainMenu(!mainMenu)
    }

    return (
        <Comp>
            <Burger
                toggled={mainMenu}
                toggle={toggle}
            />
            <SelectYear/>
            <MainMenu
                lang={props.lang}
                data={props.mainMenuData}
                open={mainMenu}
                toggle={setMainMenu}
            />

            {props.noLogo ? null : <HeaderLogo/>}

            {/*<Background/>*/}
        </Comp>
    );
};

export default Header;
