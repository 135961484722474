import React from 'react';
import styled from "styled-components";
// import {Divide as Hamburger} from 'hamburger-react'
import {BurgerIcon} from "../icons/BurgerIcon";

const Comp = styled.div`
  //margin-left: -4px;
  //margin-top: -4px;
  transition: all 0.4s;

  :hover {
    color: ${({theme}) => theme.colors.lemon};

    // .bg {
      //   background-color: ${({theme}) => theme.colors.accent};
    // }
  }

  position: relative;
  
  height: 1.5rem;
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  transform: translateX(-25%);
  background-color: ${({theme}) => theme.colors.background};
  z-index: -1;
  border-radius: 50%;
  filter: blur(15px);
  opacity: 0.8;
  transition: all 0.4s;
`

const Burger = (props) => {
    return (
        <Comp>
            {/*<Hamburger*/}
            {/*    toggled={props.toggled}*/}
            {/*    toggle={props.toggle}*/}
            {/*    rounded={true}*/}
            {/*    hideOutline={true}*/}
            {/*    label="menu"*/}
            {/*    size={40}*/}
            {/*    distance="sm"*/}
            {/*/>*/}
            <BurgerIcon onClick={props.toggle}/>
            <Background className="bg"/>
        </Comp>
    );
};

export default Burger;