import React from 'react';
import styled from "styled-components";
import Text from "../layout/Text";

const Comp  = styled.div`
  display: flex;
  text-align: center;
  //line-break: anywhere;
  filter: ${({theme}) => theme.filters.titleDropShadow};
`

const BigTitle = (props) => {
    return (
        <Comp>
            <Text s="bigTitle">
                {props.children}
            </Text>
        </Comp>
    );
};

export default BigTitle;