import React from 'react';
import styled from "styled-components";
import Text from "../layout/Text";
import Spacer from "../layout/Spacer";
import DataLink from "./DataLink";

const Comp = styled.div`

`

const Title = styled.div`
  display: flex;
`

const Number = styled.div`
  background-color: ${({theme}) => theme.colors.accent};
  width: fit-content;
  color: ${({theme}) => theme.colors.background};
  padding: 0 0.5rem;
`

const DataList = (props) => {
    // console.log(props.data)

    const links = props.data.map((item, index) => {
        return (
            <React.Fragment key={index}>
                <DataLink indent={1} to={`${props.prefix ? props.prefix : ""}${item[props.link]}`}>
                    {item.name}
                </DataLink>
            </React.Fragment>
        )
    })
    return (
        <Comp>
            <Text s="small">
                <Title>
                    {props.number ?
                        <>
                            <Number>{props.data.length}</Number>
                            <Spacer s="smaller"/>
                        </>
                        : null}
                    {props.text}:
                </Title>

                {links}
            </Text>
        </Comp>
    );
};

export default DataList;