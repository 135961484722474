import {useEffect, useState} from "react";

export default function useIntersect(objRef, targetRef) {
    const [isIntersecting, setIntersecting] = useState(false)

    useEffect(() => {
        const options = {
            root: targetRef.current
        }
        if (typeof window !== 'undefined') {
            const observer = new IntersectionObserver(
                ([entry]) => setIntersecting(entry.isIntersecting),
                options
            )
            if (objRef.current) {
                observer.observe(objRef.current)
            }
            // Remove the observer as soon as the component is unmounted
            return () => {
                observer.disconnect()
            }
        }
    }, [objRef, targetRef])

    return isIntersecting
}