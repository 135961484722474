import React from 'react';
import styled from "styled-components";
import Text from "../layout/Text";
import Spacer from "../layout/Spacer";
import DataLink from "./DataLink";

const Comp = styled.div`

`

const Cursus = (props) => {
    const {data, pageData} = props

    const field = pageData.find(i => i.identification.id === "field").content
    const diploma = pageData.find(i => i.identification.id === "diploma").content
    const option = pageData.find(i => i.identification.id === "option").content
    return (
        <Comp>
            <Text s="small">
                {field}:
            </Text>
            <DataLink indent={1}to={`/catalogue#fields=${data.authors[0].diploma.field._id}`}>
                <Text s="small">
                    {data.authors[0].diploma.field.name}
                </Text>
            </DataLink>
            <Spacer s='medium'/>

            <Text s="small">
                {diploma}:
            </Text>
            <DataLink indent={1}to={`/catalogue#fields=${data.authors[0].diploma.field._id}&diplomas=${data.authors[0].diploma._id}`}>
                <Text s="small">
                    {data.authors[0].diploma.name}
                </Text>
            </DataLink>
            <Spacer s='medium'/>

            {data.authors[0].option ?
                <>
                    <Text s="small">
                        {option}:
                    </Text>
                    <DataLink indent={1}to={`/catalogue#fields=${data.authors[0].diploma.field._id}&diplomas=${data.authors[0].diploma._id}&options=${data.authors[0].option._id}`}>
                        <Text s="small">
                            {data.authors[0].option.name}
                        </Text>
                    </DataLink>
                    <Spacer s='medium'/>
                </>
                : null}
        </Comp>
    );
};

export default Cursus;