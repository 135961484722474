import React, {useState} from 'react';
import styled from "styled-components";
import Text from "../layout/Text";
import ProjectCard from "./ProjectCard";
import Spacer from "../layout/Spacer";
import Link from "../localization/Link";

const Comp = styled.div`
  display: flex;
    // margin-right: ${p => p.even ? "2rem" : null};
    // margin-left: ${p => !p.even ? "2rem" : null};
  padding-bottom: ${({theme}) => theme.spacer.large[0]};
  //flex-wrap: wrap;
  //background-color: palegoldenrod;

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    flex-direction: column;
  }
`

const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
    // max-width: ${({theme}) => theme.cards.standardSize};
  border-radius: ${({theme}) => theme.radii.standard};
  justify-content: flex-end;
  //border: 1px solid saddlebrown;

  //position: relative;
  //width: 20vw;
  width: ${({theme}) => theme.cards.standardSize};
    // height: ${({theme}) => theme.cards.standardSize};
    // min-width: ${({theme}) => theme.cards.smallSize};
    // min-height: ${({theme}) => theme.cards.smallSize};
  // height: 100%;
  //padding-top: 100%;
  //background-color: palevioletred;

  .text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    //background-color: palegoldenrod;
  }

  @media (max-width: ${({theme}) => theme.breakPoints.l}) {
    // prev m
    width: ${({theme}) => theme.cards.smallSize};
    height: fit-content;
    justify-content: flex-start;
  }
`

const Project = (props) => {
    const {data} = props
    const [hover, setHover] = useState(false)

    const author = data.authors[0] ? data.authors[0].firstName + " " + data.authors[0].lastName : null
    const diploma = data.authors[0] ? data.authors[0].diploma.name : null
    const option = data.authors[0] && data.authors[0].option ? data.authors[0].option.name : null

    return (
        <Comp>
            <Link to={data.slug ? "/projects/" + data.slug.current : null}>
                <ProjectCard data={data} hover={hover} setHover={setHover}/>
            </Link>
            <Spacer s="medium"/>
            {/*<Conditional mobile>*/}
            {/*    <Spacer s="medium"/>*/}
            {/*</Conditional>*/}

            <InfoCard onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>

                <Link to={data.slug ? "/projects/" + data.slug.current : null} className="text">
                    <Text s="catalogueDiploma" breakPoint='l'>
                        {diploma}
                        <br/>
                        {option}
                    </Text>
                    <Spacer s="small"/>

                    <div>
                        <Text s="catalogueTitle" style={{wordWrap: "break-word"}} breakPoint='l'>
                            {data.title}
                        </Text>
                        <Spacer s="small"/>

                        <Text s="catalogueName" breakPoint='l'>
                            {author}
                        </Text>
                    </div>
                </Link>
            </InfoCard>
            <Spacer s="medium"/>
        </Comp>
    );
};

export default Project;