import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
	width: auto;
	height: 2.5rem;
	color: inherit;
`;

export const HESSOLogo = () => (
	<Svg viewBox="0 0 184.42 50">
		<g>
			<path fill="currentcolor" d="M17.75,40.82c-0.43,0-0.46,0-0.91,0.02l-0.05-0.05c0.02-0.85,0.05-1.77,0.04-2.55c-0.19,0-0.38-0.01-0.76-0.01
		c-0.63,0-1.32,0.01-1.88,0.01c-0.01,1.44,0.02,1.95,0.06,2.52l-0.04,0.05c-0.44,0-0.47,0-0.91,0.02l-0.05-0.05
		c0.03-0.75,0.04-1.13,0.04-3.43c0-1.02-0.02-1.39-0.05-1.84l0.05-0.05c0.41-0.01,0.51-0.01,0.89-0.02l0.04,0.05
		c-0.01,0.45-0.02,1.07-0.02,2.11c0.42,0.01,0.64,0.02,1.17,0.02c0.74,0,1.08-0.01,1.46-0.02c-0.02-1.44-0.02-1.61-0.06-2.08
		l0.04-0.05c0.44-0.01,0.5-0.01,0.89-0.02l0.04,0.05c-0.02,0.74-0.03,1.21-0.03,2.54c0,1.47,0.03,2.03,0.08,2.73L17.75,40.82z" />
			<path fill="currentcolor" d="M22.36,38.51c0-0.32,0-0.74-0.05-0.95c-0.17-0.59-0.75-0.78-1.34-0.78c-0.48,0-0.94,0.1-1.4,0.25l-0.05,0.05
		c-0.01,0.16-0.01,0.2-0.02,0.55l0.06,0.04c0.34-0.14,0.7-0.29,1.23-0.29c0.4,0,0.59,0.14,0.67,0.28c0.07,0.12,0.07,0.26,0.07,0.47
		l-0.14,0.02c-1.45,0.16-2.35,0.49-2.35,1.5c0,0.75,0.56,1.27,1.37,1.27c0.61,0,0.98-0.27,1.13-0.39l-0.01,0.28l0.03,0.05
		c0.37-0.02,0.44-0.03,0.81-0.03l0.04-0.04c-0.04-0.49-0.05-0.58-0.05-1.52V38.51z M21.53,38.69v1.27
		c-0.09,0.08-0.38,0.33-0.88,0.33c-0.77,0-0.77-0.65-0.77-0.69c0-0.68,0.75-0.78,1.57-0.9L21.53,38.69z" />
			<path fill="currentcolor" d="M27.4,36.9c-0.02,0.58-0.02,1.12-0.02,1.73c0,0.93,0.02,1.5,0.04,1.82c0,0.11,0.01,0.21,0.02,0.32l-0.03,0.05
		c-0.39,0.01-0.45,0.02-0.82,0.03l-0.05-0.05l0.01-0.32c-0.18,0.12-0.61,0.43-1.26,0.43c-0.31,0-0.66-0.07-0.92-0.26
		c-0.52-0.39-0.52-1.04-0.52-1.63v-0.47c-0.02-1.08-0.02-1.21-0.04-1.62l0.04-0.05c0.38-0.01,0.45-0.01,0.83-0.04l0.05,0.05
		l-0.03,1.66c0,0.21-0.01,0.36,0,0.72c0.01,0.32,0.03,1,0.84,1c0.47,0,0.81-0.25,1.01-0.39c0.01-0.41,0.01-0.76,0.01-1.2
		c0-1.05-0.01-1.27-0.06-1.76l0.05-0.05c0.37-0.01,0.46-0.01,0.83-0.03L27.4,36.9z" />
			<path fill="currentcolor" d="M30.75,40.22c-0.02,0.25-0.02,0.3-0.03,0.52l-0.04,0.05c-0.17,0.04-0.31,0.07-0.6,0.07
		c-0.19,0-0.7,0-0.95-0.39c-0.14-0.22-0.14-0.4-0.14-1.3l0.02-1.67c-0.28,0-0.29,0.01-0.58,0.02l-0.03-0.04
		c0.02-0.24,0.02-0.3,0.02-0.55l0.05-0.05c0.18,0,0.28,0.01,0.55,0c-0.01-0.16-0.01-0.32-0.01-0.48c-0.01-0.15-0.02-0.29-0.02-0.43
		L29,35.91c0.36-0.09,0.45-0.11,0.82-0.21l0.05,0.03c-0.02,0.51-0.03,0.6-0.03,1.15c0.34-0.01,0.44-0.01,0.89-0.02l0.04,0.05
		c-0.02,0.2-0.02,0.28-0.02,0.54l-0.04,0.04c-0.4-0.01-0.45-0.01-0.88-0.01l-0.02,1.41c0.01,0.09,0.01,0.53,0.01,0.63
		c0,0.56,0.07,0.73,0.47,0.73c0.17,0,0.3-0.04,0.39-0.07L30.75,40.22z" />
			<path fill="currentcolor" d="M35.06,39.86c-0.1,0.08-0.28,0.22-0.58,0.33c-0.23,0.08-0.47,0.11-0.7,0.11c-0.25,0-0.71-0.04-1.04-0.41
		c-0.27-0.31-0.3-0.68-0.3-0.95c1.32-0.01,1.54-0.01,2.79,0l0.05-0.05c0.01-0.33,0.02-1.08-0.43-1.6c-0.24-0.27-0.68-0.52-1.32-0.52
		c-1.05,0-1.96,0.69-1.96,2.1c0,1.32,0.83,2.03,2.15,2.03c0.72,0,1.15-0.22,1.29-0.29l0.05-0.05c0.02-0.29,0.02-0.37,0.07-0.68
		L35.06,39.86z M34.46,38.37c-0.74,0.01-1.05,0.02-2.02,0.01c0.08-0.76,0.61-1.05,1.06-1.05c0.36,0,0.72,0.17,0.88,0.59
		C34.44,38.09,34.45,38.26,34.46,38.37" />
			<path fill="currentcolor" d="M42.75,40.2c-0.02,0.26-0.02,0.31-0.04,0.57l-0.05,0.05c-1.57,0-1.82,0.01-3.35,0.02l-0.04-0.05
		c0.02-0.6,0.05-1.01,0.05-2.4c0-1.27,0-2-0.06-2.88l0.05-0.05c1.59-0.01,1.9-0.01,3.38-0.02l0.05,0.05
		c-0.03,0.23-0.04,0.32-0.06,0.59l-0.05,0.05c-1.04-0.03-1.39-0.03-2.41-0.04c-0.03,0.7-0.03,0.92-0.03,1.62c0.99,0,1.3,0,2.28-0.02
		l0.04,0.05c-0.03,0.27-0.03,0.34-0.04,0.58l-0.05,0.05c-0.93-0.02-1.14-0.02-2.23-0.02c-0.02,0.88-0.01,1.1,0,1.82
		c1.22,0,1.47,0,2.51-0.04L42.75,40.2z" />
			<path fill="currentcolor" d="M46.99,39.92c-0.03,0.32-0.04,0.36-0.06,0.68l-0.06,0.05c-0.23,0.11-0.55,0.26-1.11,0.26
		c-1.22,0-2.01-0.83-2.01-2.01c0-1.21,0.77-2.12,2.02-2.12c0.52,0,0.89,0.16,1.1,0.24l0.03,0.05c-0.03,0.27-0.04,0.37-0.06,0.6
		l-0.06,0.03c-0.27-0.18-0.57-0.3-0.89-0.3c-0.67,0-1.26,0.49-1.26,1.45c0,1.03,0.62,1.41,1.25,1.41c0.55,0,0.89-0.26,1.04-0.38
		L46.99,39.92z" />
			<path fill="currentcolor" d="M51.78,38.79c-0.02-0.53-0.12-1.07-0.49-1.47c-0.35-0.38-0.89-0.55-1.44-0.55c-0.8,0-1.27,0.31-1.5,0.53
		c-0.54,0.51-0.56,1.25-0.56,1.54c0,0.25,0.01,1.02,0.56,1.55c0.26,0.25,0.72,0.51,1.44,0.51c0.56,0,1.04-0.13,1.39-0.47
		c0.26-0.25,0.44-0.55,0.52-0.92C51.77,39.3,51.78,39.05,51.78,38.79 M50.91,38.82c0.01,0.16,0,0.67-0.19,1.01
		c-0.23,0.42-0.62,0.51-0.92,0.51c-0.16,0-0.33-0.03-0.47-0.1c-0.64-0.3-0.65-1.07-0.65-1.38c0-0.32,0.03-0.76,0.24-1.07
		c0.28-0.43,0.73-0.44,0.89-0.44c0.45,0,0.71,0.2,0.86,0.43C50.87,38.12,50.91,38.63,50.91,38.82" />
			<path fill="currentcolor" d="M53.94,40.82c-0.39,0.01-0.47,0.02-0.85,0.03l-0.04-0.05c0.02-0.54,0.04-1.22,0.04-2.68
		c0-1.05,0-2.17-0.08-3.14l0.04-0.04c0.39-0.06,0.48-0.08,0.88-0.16l0.05,0.05c-0.05,0.99-0.05,1.99-0.05,2.99
		c0,1.66,0.02,2.24,0.06,2.96L53.94,40.82z" />
			<path fill="currentcolor" d="M58.72,39.86c-0.1,0.08-0.28,0.22-0.58,0.33c-0.23,0.08-0.47,0.11-0.7,0.11c-0.25,0-0.71-0.04-1.04-0.41
		c-0.27-0.31-0.3-0.68-0.31-0.95c1.32-0.01,1.54-0.01,2.79,0l0.06-0.05c0.01-0.33,0.02-1.08-0.44-1.6
		c-0.24-0.27-0.68-0.52-1.32-0.52c-1.06,0-1.96,0.69-1.96,2.1c0,1.32,0.83,2.03,2.15,2.03c0.72,0,1.15-0.22,1.29-0.29l0.05-0.05
		c0.02-0.29,0.03-0.37,0.07-0.68L58.72,39.86z M58.12,38.37c-0.74,0.01-1.05,0.02-2.02,0.01c0.08-0.76,0.61-1.05,1.06-1.05
		c0.36,0,0.72,0.17,0.88,0.59C58.1,38.09,58.11,38.26,58.12,38.37" />
			<path fill="currentcolor" d="M65,37.79c0.59,0.24,1.37,0.56,1.37,1.54c0,0.85-0.67,1.61-2.07,1.61c-0.85,0-1.4-0.24-1.59-0.33l-0.03-0.05
		c0.03-0.26,0.04-0.41,0.07-0.7l0.08-0.03c0.2,0.13,0.67,0.45,1.42,0.45c0.83,0,1.18-0.4,1.18-0.86c0-0.53-0.47-0.74-0.99-0.93
		l-0.4-0.17c-0.57-0.25-1.34-0.58-1.34-1.5c0-0.28,0.08-0.57,0.25-0.79c0.37-0.5,1.04-0.67,1.69-0.67c0.64,0,1.02,0.12,1.45,0.24
		l0.04,0.06c-0.03,0.29-0.04,0.39-0.06,0.67l-0.07,0.03c-0.35-0.17-0.72-0.35-1.33-0.35c-0.67,0-1.09,0.29-1.09,0.74
		c0,0.34,0.24,0.5,0.41,0.6c0.1,0.06,0.14,0.08,0.62,0.27L65,37.79z" />
			<path fill="currentcolor" d="M68.49,36.87l-0.06-0.05c-0.36,0.07-0.45,0.09-0.84,0.15l-0.04,0.05c0.1,0.86,0.1,1.39,0.1,2.57
		c0,0.45,0.01,0.9,0.01,1.34c0,0.78-0.02,1.25-0.03,1.71l0.04,0.05c0.28-0.01,0.56-0.02,0.85-0.02l0.05-0.05
		c-0.04-0.71-0.06-0.91-0.06-1.82c0.11,0.01,0.21,0.02,0.36,0.02c1.7,0,2.53-1.01,2.53-2.18c0-1.07-0.72-1.86-1.74-1.86
		c-0.63,0-1.01,0.29-1.17,0.41L68.49,36.87z M68.49,40.24c-0.01-0.44-0.02-0.89-0.02-1.34c0-0.38,0.01-0.77,0.02-1.17
		c0.19-0.12,0.49-0.32,0.93-0.32c0.64,0,1.09,0.49,1.09,1.27c0,0.93-0.61,1.57-1.68,1.57C68.69,40.26,68.6,40.25,68.49,40.24" />
			<path fill="currentcolor" d="M75.95,39.86c-0.1,0.08-0.28,0.22-0.58,0.33c-0.23,0.08-0.47,0.11-0.7,0.11c-0.25,0-0.71-0.04-1.04-0.41
		c-0.28-0.31-0.3-0.68-0.31-0.95c1.32-0.01,1.54-0.01,2.79,0l0.06-0.05c0.01-0.33,0.02-1.08-0.44-1.6
		c-0.24-0.27-0.68-0.52-1.32-0.52c-1.06,0-1.96,0.69-1.96,2.1c0,1.32,0.83,2.03,2.15,2.03c0.72,0,1.15-0.22,1.29-0.29l0.05-0.05
		c0.02-0.29,0.02-0.37,0.06-0.68L75.95,39.86z M75.31,35.19C75.15,35.2,75,35.2,74.85,35.2c-0.15,0-0.3,0-0.47-0.01l-0.05,0.04
		c-0.18,0.49-0.41,0.98-0.49,1.16l0.03,0.05c0.28,0,0.37,0,0.59,0.01l0.05-0.03c0.36-0.55,0.44-0.66,0.83-1.17L75.31,35.19z
		 M75.34,38.37c-0.74,0.01-1.05,0.02-2.02,0.01c0.08-0.76,0.61-1.05,1.07-1.05c0.36,0,0.72,0.17,0.87,0.59
		C75.33,38.09,75.34,38.26,75.34,38.37" />
			<path fill="currentcolor" d="M80.43,39.92c-0.03,0.32-0.04,0.36-0.06,0.68l-0.06,0.05c-0.23,0.11-0.55,0.26-1.11,0.26
		c-1.22,0-2.01-0.83-2.01-2.01c0-1.21,0.77-2.12,2.02-2.12c0.52,0,0.89,0.16,1.1,0.24l0.03,0.05c-0.03,0.27-0.04,0.37-0.05,0.6
		l-0.06,0.03c-0.27-0.18-0.57-0.3-0.89-0.3c-0.67,0-1.26,0.49-1.26,1.45c0,1.03,0.62,1.41,1.25,1.41c0.55,0,0.89-0.26,1.04-0.38
		L80.43,39.92z" />
			<path fill="currentcolor" d="M82.35,35.77c-0.41,0.02-0.5,0.02-0.89,0.06l-0.05-0.05c0.01-0.1,0.01-0.2,0.01-0.3
		c0-0.14-0.01-0.26-0.02-0.41l0.06-0.04c0.42-0.03,0.49-0.04,0.89-0.08L82.39,35c-0.01,0.31-0.01,0.39,0,0.72L82.35,35.77z
		 M82.34,40.82c-0.42,0.01-0.48,0.01-0.88,0.03l-0.04-0.05c0.02-0.4,0.05-0.75,0.05-1.6c0-1.45-0.03-1.72-0.08-2.17l0.04-0.05
		c0.35-0.05,0.47-0.07,0.87-0.15l0.08,0.05c-0.02,0.39-0.05,0.8-0.05,1.78c0,1.41,0.02,1.6,0.05,2.12L82.34,40.82z" />
			<path fill="currentcolor" d="M86.9,38.51c0-0.32,0-0.74-0.06-0.95c-0.17-0.59-0.75-0.78-1.34-0.78c-0.47,0-0.94,0.1-1.39,0.25l-0.05,0.05
		c-0.01,0.16-0.01,0.2-0.02,0.55l0.06,0.04c0.34-0.14,0.7-0.29,1.23-0.29c0.4,0,0.59,0.14,0.67,0.28c0.07,0.12,0.07,0.26,0.07,0.47
		l-0.14,0.02c-1.45,0.16-2.35,0.49-2.35,1.5c0,0.75,0.56,1.27,1.37,1.27c0.61,0,0.98-0.27,1.13-0.39l-0.01,0.28l0.03,0.05
		c0.37-0.02,0.44-0.03,0.82-0.03l0.04-0.04c-0.04-0.49-0.05-0.58-0.05-1.52V38.51z M86.07,38.69v1.27
		c-0.09,0.08-0.38,0.33-0.88,0.33c-0.77,0-0.77-0.65-0.77-0.69c0-0.68,0.76-0.78,1.57-0.9L86.07,38.69z" />
			<path fill="currentcolor" d="M89.26,40.82c-0.39,0.01-0.47,0.02-0.85,0.03l-0.04-0.05c0.02-0.54,0.04-1.22,0.04-2.68
		c0-1.05,0-2.17-0.08-3.14l0.04-0.04c0.4-0.06,0.47-0.08,0.88-0.16l0.05,0.05c-0.06,0.99-0.06,1.99-0.06,2.99
		c0,1.66,0.02,2.24,0.06,2.96L89.26,40.82z" />
			<path fill="currentcolor" d="M91.66,35.77c-0.41,0.02-0.5,0.02-0.89,0.06l-0.05-0.05c0.01-0.1,0.01-0.2,0.01-0.3
		c0-0.14-0.01-0.26-0.02-0.41l0.06-0.04c0.42-0.03,0.49-0.04,0.89-0.08L91.7,35c-0.01,0.31-0.01,0.39,0,0.72L91.66,35.77z
		 M91.65,40.82c-0.42,0.01-0.48,0.01-0.88,0.03l-0.04-0.05c0.02-0.4,0.05-0.75,0.05-1.6c0-1.45-0.02-1.72-0.08-2.17l0.04-0.05
		c0.35-0.05,0.47-0.07,0.87-0.15l0.08,0.05c-0.02,0.39-0.05,0.8-0.05,1.78c0,1.41,0.02,1.6,0.05,2.12L91.65,40.82z" />
			<path fill="currentcolor" d="M94.84,38.52c0.32,0.1,1.05,0.33,1.05,1.14c0,0.3-0.12,0.65-0.45,0.9c-0.43,0.34-1,0.36-1.29,0.36
		c-0.44,0-0.8-0.08-1.1-0.19c-0.04-0.02-0.08-0.03-0.12-0.05l-0.04-0.07c0.03-0.3,0.03-0.33,0.05-0.64l0.08-0.02
		c0.14,0.1,0.5,0.36,1.12,0.36c0.64,0,0.87-0.33,0.87-0.57c0-0.35-0.35-0.46-0.84-0.62l-0.26-0.08c-0.33-0.1-1.05-0.32-1.05-1.1
		c0-0.78,0.72-1.17,1.63-1.17c0.59,0,1.01,0.16,1.16,0.21l0.04,0.05c-0.03,0.28-0.04,0.31-0.06,0.6l-0.07,0.03
		c-0.2-0.12-0.52-0.3-1.04-0.3c-0.11,0-0.23,0.01-0.35,0.03c-0.24,0.06-0.44,0.22-0.44,0.46c0,0.33,0.33,0.42,0.83,0.57L94.84,38.52
		z" />
			<path fill="currentcolor" d="M100.3,39.86c-0.1,0.08-0.28,0.22-0.58,0.33c-0.23,0.08-0.47,0.11-0.7,0.11c-0.25,0-0.71-0.04-1.04-0.41
		c-0.27-0.31-0.3-0.68-0.3-0.95c1.32-0.01,1.54-0.01,2.79,0l0.06-0.05c0.01-0.33,0.02-1.08-0.44-1.6c-0.24-0.27-0.68-0.52-1.32-0.52
		c-1.05,0-1.96,0.69-1.96,2.1c0,1.32,0.83,2.03,2.15,2.03c0.72,0,1.15-0.22,1.29-0.29l0.05-0.05c0.02-0.29,0.02-0.37,0.07-0.68
		L100.3,39.86z M99.66,35.19c-0.15,0.01-0.31,0.01-0.46,0.01c-0.16,0-0.3,0-0.47-0.01l-0.05,0.04c-0.18,0.49-0.41,0.98-0.49,1.16
		l0.03,0.05c0.28,0,0.37,0,0.59,0.01l0.06-0.03c0.36-0.55,0.43-0.66,0.83-1.17L99.66,35.19z M99.7,38.37
		c-0.74,0.01-1.05,0.02-2.02,0.01c0.08-0.76,0.61-1.05,1.06-1.05c0.36,0,0.72,0.17,0.88,0.59C99.68,38.09,99.69,38.26,99.7,38.37" />
			<path fill="currentcolor" d="M105.03,39.86c-0.1,0.08-0.28,0.22-0.58,0.33c-0.23,0.08-0.47,0.11-0.7,0.11c-0.25,0-0.71-0.04-1.04-0.41
		c-0.27-0.31-0.3-0.68-0.3-0.95c1.32-0.01,1.54-0.01,2.79,0l0.05-0.05c0.01-0.33,0.02-1.08-0.43-1.6c-0.24-0.27-0.69-0.52-1.32-0.52
		c-1.06,0-1.96,0.69-1.96,2.1c0,1.32,0.83,2.03,2.15,2.03c0.72,0,1.15-0.22,1.29-0.29l0.05-0.05c0.02-0.29,0.02-0.37,0.07-0.68
		L105.03,39.86z M104.42,38.37c-0.74,0.01-1.05,0.02-2.02,0.01c0.08-0.76,0.61-1.05,1.06-1.05c0.36,0,0.72,0.17,0.88,0.59
		C104.41,38.09,104.41,38.26,104.42,38.37" />
			<path fill="currentcolor" d="M24.34,44.03c0.04,0.44,0.08,0.9,0.07,1.84c-0.22-0.01-0.3-0.01-0.47-0.01c-0.42,0-1.18,0.02-1.77,0.52
		c-0.34,0.28-0.71,0.8-0.71,1.63c0,1.15,0.74,1.95,1.81,1.95c0.59,0,0.92-0.24,1.11-0.38l-0.02,0.28l0.06,0.05
		c0.37-0.02,0.47-0.02,0.82-0.02l0.04-0.06c-0.03-0.52-0.07-1.08-0.07-2.26c0-0.76,0.01-2.64,0.07-3.7l-0.06-0.05
		c-0.36,0.08-0.44,0.09-0.86,0.16L24.34,44.03z M24.37,48.95c-0.11,0.09-0.41,0.33-0.9,0.33c-0.42,0-0.68-0.18-0.83-0.34
		c-0.17-0.19-0.3-0.5-0.3-0.93c0-0.68,0.29-1.03,0.47-1.19c0.38-0.34,0.92-0.36,1.17-0.36c0.17,0,0.26,0.02,0.4,0.02
		C24.39,47.73,24.39,47.96,24.37,48.95" />
			<path fill="currentcolor" d="M30.01,48.92c-0.1,0.09-0.28,0.22-0.58,0.34c-0.23,0.07-0.47,0.11-0.7,0.11c-0.25,0-0.71-0.04-1.04-0.41
		c-0.27-0.31-0.3-0.68-0.31-0.95c1.32-0.01,1.54-0.01,2.8,0l0.05-0.05c0.01-0.33,0.02-1.08-0.44-1.6c-0.24-0.27-0.68-0.52-1.32-0.52
		c-1.05,0-1.96,0.69-1.96,2.1c0,1.32,0.83,2.03,2.15,2.03c0.72,0,1.15-0.22,1.29-0.29l0.05-0.05c0.02-0.3,0.02-0.37,0.06-0.68
		L30.01,48.92z M29.41,47.43c-0.74,0.01-1.05,0.02-2.02,0.01c0.08-0.76,0.61-1.05,1.06-1.05c0.36,0,0.72,0.18,0.88,0.59
		C29.39,47.15,29.4,47.32,29.41,47.43" />
			<path fill="currentcolor" d="M36.29,46.85c0.59,0.24,1.37,0.56,1.37,1.53c0,0.85-0.67,1.62-2.07,1.62c-0.85,0-1.4-0.24-1.59-0.34
		l-0.03-0.05c0.03-0.27,0.04-0.41,0.06-0.7l0.08-0.03c0.2,0.13,0.67,0.45,1.42,0.45c0.83,0,1.18-0.4,1.18-0.86
		c0-0.53-0.47-0.74-0.99-0.93l-0.4-0.17c-0.57-0.25-1.34-0.58-1.34-1.5c0-0.28,0.08-0.57,0.25-0.8c0.37-0.5,1.04-0.67,1.69-0.67
		c0.63,0,1.02,0.11,1.45,0.24l0.04,0.06c-0.03,0.29-0.04,0.39-0.06,0.67l-0.07,0.03c-0.35-0.17-0.73-0.35-1.33-0.35
		c-0.67,0-1.09,0.3-1.09,0.75c0,0.34,0.24,0.5,0.41,0.6c0.1,0.06,0.14,0.07,0.62,0.27L36.29,46.85z" />
			<path fill="currentcolor" d="M42.47,45.96c-0.02,0.58-0.02,1.12-0.02,1.73c0,0.93,0.02,1.5,0.04,1.82c0,0.11,0.01,0.21,0.02,0.32
		l-0.03,0.05c-0.39,0.01-0.45,0.01-0.82,0.03l-0.05-0.05l0.01-0.32c-0.18,0.12-0.61,0.43-1.26,0.43c-0.31,0-0.66-0.07-0.92-0.26
		c-0.52-0.4-0.52-1.04-0.52-1.63v-0.47c-0.02-1.09-0.02-1.21-0.04-1.62l0.04-0.05c0.38-0.01,0.45-0.01,0.83-0.04l0.05,0.05
		l-0.03,1.66c0,0.21-0.01,0.36,0,0.72c0.01,0.32,0.02,1,0.84,1c0.47,0,0.82-0.25,1.01-0.39c0.01-0.41,0.01-0.76,0.01-1.2
		c0-1.05-0.01-1.27-0.06-1.76l0.05-0.05c0.37,0,0.46,0,0.83-0.03L42.47,45.96z" />
			<path fill="currentcolor" d="M44.86,44.83c-0.41,0.02-0.5,0.02-0.9,0.06l-0.05-0.05c0.01-0.1,0.01-0.21,0.01-0.31
		c0-0.14-0.01-0.26-0.02-0.41l0.05-0.05c0.42-0.03,0.49-0.04,0.9-0.07l0.05,0.05c-0.01,0.31-0.01,0.38,0,0.72L44.86,44.83z
		 M44.85,49.88c-0.42,0.01-0.48,0.01-0.88,0.03l-0.04-0.06c0.02-0.4,0.05-0.75,0.05-1.59c0-1.45-0.02-1.72-0.08-2.17l0.04-0.05
		c0.35-0.05,0.47-0.07,0.87-0.15l0.08,0.05c-0.02,0.39-0.05,0.8-0.05,1.78c0,1.41,0.02,1.6,0.05,2.11L44.85,49.88z" />
			<path fill="currentcolor" d="M48.04,47.58c0.32,0.1,1.05,0.33,1.05,1.14c0,0.31-0.12,0.65-0.45,0.9c-0.43,0.34-1,0.36-1.29,0.36
		c-0.43,0-0.8-0.08-1.1-0.19c-0.04-0.01-0.08-0.03-0.12-0.05l-0.04-0.07c0.03-0.3,0.03-0.33,0.05-0.63l0.08-0.02
		c0.14,0.1,0.5,0.36,1.12,0.36c0.64,0,0.87-0.33,0.87-0.57c0-0.35-0.34-0.46-0.84-0.61l-0.26-0.08c-0.33-0.1-1.06-0.32-1.06-1.1
		c0-0.78,0.73-1.17,1.63-1.17c0.6,0,1.01,0.16,1.16,0.21l0.04,0.05c-0.03,0.28-0.04,0.31-0.06,0.6l-0.07,0.03
		c-0.2-0.12-0.51-0.31-1.04-0.31c-0.11,0-0.23,0.01-0.35,0.04c-0.24,0.06-0.44,0.22-0.44,0.45c0,0.33,0.33,0.43,0.83,0.57
		L48.04,47.58z" />
			<path fill="currentcolor" d="M51.92,47.58c0.32,0.1,1.05,0.33,1.05,1.14c0,0.31-0.12,0.65-0.45,0.9c-0.43,0.34-1,0.36-1.29,0.36
		c-0.44,0-0.8-0.08-1.1-0.19c-0.04-0.01-0.08-0.03-0.12-0.05l-0.04-0.07c0.03-0.3,0.03-0.33,0.05-0.63l0.08-0.02
		c0.15,0.1,0.5,0.36,1.12,0.36c0.64,0,0.87-0.33,0.87-0.57c0-0.35-0.35-0.46-0.84-0.61L51,48.11c-0.33-0.1-1.05-0.32-1.05-1.1
		c0-0.78,0.72-1.17,1.63-1.17c0.59,0,1.01,0.16,1.16,0.21l0.04,0.05c-0.03,0.28-0.04,0.31-0.06,0.6l-0.07,0.03
		c-0.2-0.12-0.52-0.31-1.04-0.31c-0.11,0-0.23,0.01-0.35,0.04c-0.24,0.06-0.44,0.22-0.44,0.45c0,0.33,0.33,0.43,0.83,0.57
		L51.92,47.58z" />
			<path fill="currentcolor" d="M57.38,48.92c-0.1,0.09-0.28,0.22-0.58,0.34c-0.23,0.07-0.47,0.11-0.7,0.11c-0.25,0-0.71-0.04-1.04-0.41
		c-0.27-0.31-0.3-0.68-0.31-0.95c1.32-0.01,1.54-0.01,2.8,0l0.05-0.05c0.01-0.33,0.02-1.08-0.44-1.6c-0.24-0.27-0.68-0.52-1.32-0.52
		c-1.05,0-1.96,0.69-1.96,2.1c0,1.32,0.83,2.03,2.15,2.03c0.72,0,1.15-0.22,1.29-0.29l0.05-0.05c0.02-0.3,0.02-0.37,0.06-0.68
		L57.38,48.92z M56.78,47.43c-0.74,0.01-1.05,0.02-2.02,0.01c0.08-0.76,0.61-1.05,1.06-1.05c0.36,0,0.72,0.18,0.88,0.59
		C56.76,47.15,56.77,47.32,56.78,47.43" />
			<path fill="currentcolor" d="M65.32,47.85c-0.02-0.53-0.12-1.06-0.49-1.47c-0.35-0.38-0.89-0.54-1.44-0.54c-0.8,0-1.27,0.31-1.5,0.53
		c-0.54,0.51-0.56,1.25-0.56,1.54c0,0.25,0.01,1.02,0.56,1.55c0.26,0.25,0.72,0.51,1.44,0.51c0.56,0,1.04-0.13,1.39-0.46
		c0.27-0.25,0.44-0.54,0.52-0.92C65.31,48.36,65.32,48.11,65.32,47.85 M64.45,47.88c0.01,0.16,0,0.67-0.19,1.01
		c-0.23,0.42-0.62,0.51-0.92,0.51c-0.16,0-0.33-0.03-0.47-0.1c-0.64-0.3-0.65-1.07-0.65-1.37c0-0.32,0.02-0.76,0.24-1.07
		c0.28-0.43,0.73-0.44,0.89-0.44c0.45,0,0.71,0.2,0.86,0.44C64.42,47.18,64.45,47.69,64.45,47.88" />
			<path fill="currentcolor" d="M69.63,48.98c-0.03,0.33-0.04,0.36-0.06,0.68l-0.06,0.05c-0.24,0.11-0.55,0.26-1.11,0.26
		c-1.22,0-2.01-0.83-2.01-2.01c0-1.2,0.77-2.11,2.02-2.11c0.52,0,0.89,0.16,1.1,0.24l0.03,0.05c-0.03,0.27-0.04,0.37-0.06,0.6
		l-0.06,0.03c-0.26-0.18-0.57-0.29-0.89-0.29c-0.67,0-1.26,0.48-1.26,1.45c0,1.03,0.62,1.4,1.25,1.4c0.55,0,0.89-0.26,1.04-0.38
		L69.63,48.98z" />
			<path fill="currentcolor" d="M73.69,48.98c-0.03,0.33-0.04,0.36-0.07,0.68l-0.06,0.05c-0.23,0.11-0.55,0.26-1.11,0.26
		c-1.22,0-2.01-0.83-2.01-2.01c0-1.2,0.77-2.11,2.02-2.11c0.52,0,0.89,0.16,1.1,0.24l0.03,0.05c-0.03,0.27-0.04,0.37-0.06,0.6
		l-0.06,0.03c-0.27-0.18-0.57-0.29-0.89-0.29c-0.67,0-1.26,0.48-1.26,1.45c0,1.03,0.62,1.4,1.25,1.4c0.55,0,0.89-0.26,1.04-0.38
		L73.69,48.98z" />
			<path fill="currentcolor" d="M75.6,44.83c-0.41,0.02-0.5,0.02-0.89,0.06l-0.05-0.05c0.01-0.1,0.01-0.21,0.01-0.31
		c0-0.14-0.01-0.26-0.01-0.41l0.05-0.05c0.42-0.03,0.49-0.04,0.89-0.07l0.05,0.05c-0.01,0.31-0.01,0.38,0,0.72L75.6,44.83z
		 M75.59,49.88c-0.42,0.01-0.48,0.01-0.88,0.03l-0.04-0.06c0.02-0.4,0.05-0.75,0.05-1.59c0-1.45-0.02-1.72-0.08-2.17l0.04-0.05
		c0.35-0.05,0.47-0.07,0.87-0.15l0.08,0.05c-0.02,0.39-0.05,0.8-0.05,1.78c0,1.41,0.02,1.6,0.05,2.11L75.59,49.88z" />
			<path fill="currentcolor" d="M79.74,44.03c0.04,0.44,0.08,0.9,0.07,1.84c-0.22-0.01-0.3-0.01-0.47-0.01c-0.42,0-1.19,0.02-1.77,0.52
		c-0.34,0.28-0.71,0.8-0.71,1.63c0,1.15,0.74,1.95,1.81,1.95c0.59,0,0.92-0.24,1.11-0.38l-0.02,0.28l0.06,0.05
		c0.37-0.02,0.47-0.02,0.82-0.02l0.04-0.06c-0.03-0.52-0.06-1.08-0.06-2.26c0-0.76,0.01-2.64,0.06-3.7l-0.06-0.05
		c-0.36,0.08-0.44,0.09-0.85,0.16L79.74,44.03z M79.78,48.95c-0.11,0.09-0.41,0.33-0.9,0.33c-0.42,0-0.68-0.18-0.83-0.34
		c-0.17-0.19-0.3-0.5-0.3-0.93c0-0.68,0.29-1.03,0.47-1.19c0.39-0.34,0.92-0.36,1.17-0.36c0.17,0,0.27,0.02,0.4,0.02
		C79.8,47.73,79.8,47.96,79.78,48.95" />
			<path fill="currentcolor" d="M85.42,48.92c-0.1,0.09-0.28,0.22-0.58,0.34c-0.23,0.07-0.47,0.11-0.7,0.11c-0.25,0-0.71-0.04-1.04-0.41
		c-0.28-0.31-0.3-0.68-0.31-0.95c1.32-0.01,1.54-0.01,2.79,0l0.06-0.05c0.01-0.33,0.03-1.08-0.43-1.6
		c-0.24-0.27-0.68-0.52-1.32-0.52c-1.06,0-1.96,0.69-1.96,2.1c0,1.32,0.83,2.03,2.15,2.03c0.72,0,1.15-0.22,1.29-0.29l0.05-0.05
		c0.02-0.3,0.02-0.37,0.07-0.68L85.42,48.92z M84.82,47.43c-0.74,0.01-1.05,0.02-2.02,0.01c0.08-0.76,0.61-1.05,1.07-1.05
		c0.36,0,0.72,0.18,0.88,0.59C84.8,47.15,84.81,47.32,84.82,47.43" />
			<path fill="currentcolor" d="M90.44,49.88c-0.39,0.01-0.44,0.01-0.81,0.03l-0.05-0.06c0-0.08,0.01-0.16,0.01-0.25
		c0.01-0.37,0.01-0.74,0.01-1.12c0-1.17-0.01-1.24-0.05-1.4c-0.15-0.58-0.61-0.58-0.73-0.58c-0.48,0-0.85,0.26-1.07,0.42
		c-0.01,1.32,0.01,2.01,0.06,2.9l-0.05,0.05c-0.39,0.01-0.46,0.01-0.84,0.03l-0.04-0.06c0.01-0.42,0.02-0.83,0.02-1.39
		c0-1.21-0.06-1.86-0.1-2.37l0.05-0.05c0.4-0.07,0.47-0.08,0.86-0.15l0.05,0.05l-0.01,0.39c0.21-0.14,0.71-0.47,1.42-0.47
		c0.2,0,0.45,0.03,0.67,0.14c0.21,0.11,0.38,0.29,0.47,0.49c0.1,0.23,0.11,0.45,0.12,0.93v0.89c0.02,0.89,0.03,1.23,0.06,1.52
		L90.44,49.88z" />
			<path fill="currentcolor" d="M93.77,49.28c-0.02,0.25-0.02,0.31-0.03,0.52l-0.04,0.04c-0.17,0.04-0.31,0.07-0.6,0.07
		c-0.19,0-0.7,0-0.95-0.39C92,49.31,92,49.12,92,48.23l0.02-1.67c-0.28,0-0.29,0.01-0.58,0.01l-0.03-0.04
		c0.02-0.24,0.02-0.31,0.02-0.55l0.05-0.05c0.18,0,0.28,0,0.55,0c-0.01-0.16-0.01-0.33-0.01-0.49C92,45.31,92,45.17,91.99,45.02
		l0.04-0.05c0.36-0.08,0.45-0.11,0.82-0.21l0.05,0.03c-0.02,0.5-0.03,0.6-0.03,1.15c0.34,0,0.43,0,0.89-0.02l0.04,0.05
		c-0.02,0.19-0.03,0.28-0.03,0.54l-0.04,0.05c-0.4-0.01-0.45-0.02-0.88-0.02l-0.02,1.41c0.01,0.09,0.01,0.52,0.01,0.63
		c0,0.55,0.07,0.73,0.47,0.73c0.17,0,0.3-0.04,0.39-0.07L93.77,49.28z" />
			<path fill="currentcolor" d="M97.89,47.57c0-0.32,0-0.73-0.06-0.95c-0.17-0.59-0.75-0.78-1.34-0.78c-0.48,0-0.94,0.1-1.4,0.25l-0.05,0.05
		c-0.01,0.16-0.01,0.21-0.02,0.55l0.07,0.03c0.34-0.14,0.7-0.29,1.22-0.29c0.4,0,0.59,0.14,0.67,0.28c0.07,0.12,0.07,0.26,0.07,0.47
		l-0.14,0.01c-1.45,0.16-2.35,0.49-2.35,1.5c0,0.75,0.57,1.26,1.37,1.26c0.61,0,0.97-0.27,1.13-0.38l-0.01,0.27l0.03,0.05
		c0.37-0.02,0.44-0.02,0.81-0.03l0.04-0.04c-0.04-0.5-0.05-0.58-0.05-1.52V47.57z M97.06,47.75v1.27c-0.09,0.07-0.38,0.33-0.88,0.33
		c-0.76,0-0.76-0.65-0.76-0.69c0-0.68,0.75-0.78,1.57-0.9L97.06,47.75z" />
			<path fill="currentcolor" d="M100.24,49.88c-0.39,0.01-0.47,0.01-0.85,0.03l-0.04-0.06c0.02-0.53,0.04-1.22,0.04-2.68
		c0-1.05,0-2.17-0.08-3.14l0.04-0.05c0.4-0.06,0.48-0.07,0.88-0.16l0.05,0.05c-0.05,0.99-0.05,1.99-0.05,2.99
		c0,1.66,0.02,2.24,0.06,2.96L100.24,49.88z" />
			<path fill="currentcolor" d="M105.03,48.92c-0.1,0.09-0.28,0.22-0.58,0.34c-0.23,0.07-0.47,0.11-0.7,0.11c-0.25,0-0.71-0.04-1.04-0.41
		c-0.27-0.31-0.3-0.68-0.3-0.95c1.32-0.01,1.54-0.01,2.79,0l0.05-0.05c0.01-0.33,0.02-1.08-0.43-1.6c-0.24-0.27-0.69-0.52-1.32-0.52
		c-1.06,0-1.96,0.69-1.96,2.1c0,1.32,0.83,2.03,2.15,2.03c0.72,0,1.15-0.22,1.29-0.29l0.05-0.05c0.02-0.3,0.02-0.37,0.07-0.68
		L105.03,48.92z M104.42,47.43c-0.74,0.01-1.05,0.02-2.02,0.01c0.08-0.76,0.61-1.05,1.06-1.05c0.36,0,0.72,0.18,0.88,0.59
		C104.41,47.15,104.41,47.32,104.42,47.43" />
			<path fill="currentcolor" d="M81.68,17.25c1.4,0.43,4.58,1.45,4.58,4.94c0,1.32-0.52,2.8-1.96,3.89c-1.85,1.48-4.33,1.58-5.59,1.58
		c-1.89,0-3.46-0.33-4.79-0.83c-0.18-0.06-0.35-0.13-0.53-0.2l-0.17-0.3c0.14-1.28,0.14-1.45,0.21-2.77l0.35-0.1
		c0.63,0.46,2.17,1.58,4.86,1.58c2.76,0,3.78-1.45,3.78-2.47c0-1.52-1.5-2.01-3.64-2.67l-1.12-0.36c-1.43-0.43-4.58-1.38-4.58-4.78
		c0-3.39,3.15-5.07,7.06-5.07c2.59,0,4.41,0.69,5.04,0.92l0.17,0.23c-0.14,1.22-0.17,1.35-0.28,2.6l-0.31,0.13
		c-0.87-0.53-2.24-1.32-4.51-1.32c-0.49,0-0.98,0.03-1.5,0.16c-1.05,0.26-1.92,0.96-1.92,1.98c0,1.41,1.43,1.84,3.6,2.47
		L81.68,17.25z" />
			<path fill="currentcolor" d="M106.51,18.44c-0.07-2.3-0.52-4.61-2.13-6.36c-1.54-1.65-3.88-2.37-6.26-2.37c-3.5,0-5.52,1.35-6.5,2.31
		c-2.34,2.21-2.41,5.43-2.41,6.69c0,1.09,0.03,4.45,2.41,6.72c1.12,1.09,3.11,2.21,6.26,2.21c2.45,0,4.51-0.56,6.05-2.01
		c1.16-1.08,1.89-2.37,2.27-3.98C106.44,20.65,106.51,19.56,106.51,18.44 M102.7,18.57c0.04,0.69,0,2.9-0.8,4.38
		c-1.01,1.81-2.69,2.21-3.98,2.21c-0.7,0-1.44-0.13-2.07-0.43c-2.76-1.32-2.8-4.64-2.8-5.96c0-1.38,0.11-3.29,1.05-4.64
		c1.22-1.85,3.18-1.91,3.85-1.91c1.96,0,3.08,0.86,3.74,1.88C102.56,15.54,102.7,17.75,102.7,18.57" />
			<path fill="currentcolor" d="M69.09,18.71c0,1.15-0.93,2.08-2.08,2.08s-2.08-0.93-2.08-2.08c0-1.15,0.93-2.08,2.08-2.08
		S69.09,17.56,69.09,18.71" />
			<path fill="currentcolor" d="M21.19,4.24c-0.04,2.53-0.07,4.08-0.07,6.39c0,5.53,0.1,11.03,0.38,16.57l-0.17,0.2
		c-2.97-0.03-3.67,0-6.29,0.1l-0.17-0.2c0.17-4.64,0.17-5.73,0.1-10.17c-1.4-0.03-2.8-0.07-4.2-0.07c-1.36,0-2.66,0.03-4.19,0.1
		c0.03,4.77,0.07,5.96,0.28,10.04l-0.17,0.2c-3.08-0.03-3.71,0-6.26,0.1l-0.17-0.2c0.07-2.76,0.14-4.38,0.14-7.34
		C0.38,11.78,0.17,7.73,0,4.33l0.21-0.2c3.04,0,3.57,0,6.26-0.1l0.17,0.2l-0.07,8.7c1.22,0,2.48,0.03,3.7,0.03
		c1.57,0,3.11-0.03,4.65-0.07c-0.03-3.89-0.03-4.84-0.28-8.56l0.17-0.2c2.94,0,3.39,0,6.19-0.1L21.19,4.24z" />
			<path fill="currentcolor" d="M41.46,22.52c-1.33,0.66-2.9,1.41-5.46,1.41c-2.1,0-3.53-0.63-4.37-1.81c-0.52-0.69-0.63-1.35-0.77-2.01
		l11.3-0.2l0.21-0.23c-0.03-1.61-0.07-3.26-0.56-4.91c-1.22-3.89-3.92-4.68-5.11-4.94c-0.87-0.23-1.68-0.3-2.48-0.3
		c-5.49,0-9.13,3.39-9.13,9.09c0,1.55,0.28,3.16,0.84,4.31c2.03,4.45,6.78,4.84,9.3,4.84c2.9,0,4.16-0.5,6.01-1.19l0.21-0.26
		l0.24-3.72L41.46,22.52z M30.87,16.62c0.1-1.02,0.35-2.41,1.68-3.2c0.42-0.26,0.91-0.36,1.4-0.36c1.15,0,1.85,0.56,2.23,1.15
		c0.49,0.72,0.52,1.52,0.52,2.27L30.87,16.62z" />
			<path fill="currentcolor" d="M56.22,16.62c1.92,0.69,4.62,1.64,4.62,5.27c0,0.23,0,0.46-0.04,0.73c-0.63,4.71-5.87,5.17-8.53,5.17
		c-2.55,0-4.02-0.53-5.74-1.12l-0.17-0.3c0.14-1.64,0.17-2.01,0.24-3.72l0.31-0.13c0.52,0.4,0.81,0.63,1.4,0.92
		c1.29,0.63,2.73,0.92,3.71,0.92c1.96,0,2.76-0.89,2.76-1.71c0-1.19-1.54-1.68-2.48-1.98l-1.33-0.46c-1.64-0.59-4.54-1.65-4.54-5.17
		c0-1.32,0.49-2.31,0.94-2.9c1.82-2.47,5.25-2.6,6.96-2.6c2.27,0,3.71,0.4,5.35,0.82l0.17,0.2l-0.35,3.59l-0.31,0.1
		c-0.87-0.53-2.24-1.38-4.37-1.38c-2.17,0-2.45,1.09-2.45,1.48c0,0.92,0.94,1.28,2.13,1.68L56.22,16.62z" />
			<path fill="currentcolor" d="M108.63,27.68c0-0.7,0.15-1.01,0.64-2.04c0.04-0.08,10.17-21.65,10.17-21.65c0.69-1.44,1.36-2.81,2.9-2.81
		c1.3,0,2.27,0.98,2.27,2.27c0,0.72-0.22,1.26-0.47,1.79c0,0-10.57,22.41-10.58,22.42c-0.58,1.17-1.19,2.37-2.7,2.37
		C109.61,30.03,108.63,29,108.63,27.68 M118.44,3.36c-0.06,0.12-10.19,21.7-10.19,21.7c-0.55,1.15-0.79,1.64-0.79,2.62
		c0,1.98,1.5,3.53,3.4,3.53c2.24,0,3.11-1.75,3.75-3.02L125.2,5.74c0.3-0.64,0.59-1.34,0.59-2.29c0-1.93-1.52-3.45-3.45-3.45
		C120.07,0,119.13,1.94,118.44,3.36" />
			<path fill="currentcolor" d="M118.33,27.67c0-0.71,0.15-1.02,0.65-2.05c0.03-0.07,10.16-21.64,10.16-21.64c0.7-1.43,1.36-2.81,2.9-2.81
		c1.29,0,2.27,0.98,2.27,2.27c0,0.72-0.23,1.26-0.48,1.79c0,0-10.53,22.32-10.58,22.42c-0.69,1.39-1.29,2.37-2.7,2.37
		C119.32,30.03,118.33,29,118.33,27.67 M128.14,3.36c-0.06,0.12-10.19,21.7-10.19,21.7c-0.55,1.15-0.79,1.64-0.79,2.62
		c0,1.98,1.5,3.53,3.41,3.53c2.24,0,3.12-1.75,3.75-3.02l10.59-22.44c0.3-0.64,0.59-1.34,0.59-2.29c0-1.93-1.51-3.45-3.44-3.45
		C129.77,0,128.83,1.94,128.14,3.36" />
			<path fill="currentcolor" d="M141.87,20.83c0.97,0,1.36,0.49,1.36,1.48c0,2.98-1.95,5.22-5.03,5.22c-3.13,0-5.31-2.48-5.31-5.68
		c0-3.21,2.16-5.68,5.31-5.68c2.33,0,4.24,1.1,4.24,2.18c0,0.66-0.42,1.08-0.96,1.08c-1.05,0-1.27-1.13-3.29-1.13
		c-1.93,0-2.9,1.68-2.9,3.55c0,1.88,0.94,3.55,2.9,3.55c1.22,0,2.62-0.68,2.62-2.44h-1.57c-0.63,0-1.08-0.45-1.08-1.08
		c0-0.65,0.49-1.05,1.08-1.05H141.87z" />
			<path fill="currentcolor" d="M144.06,17.7c0-0.77,0.38-1.34,1.2-1.34h3.83c0.74,0,1.1,0.52,1.1,1.07c0,0.52-0.37,1.07-1.1,1.07h-2.72v2.25
		h2.53c0.75,0,1.12,0.53,1.12,1.07c0,0.52-0.38,1.07-1.12,1.07h-2.53v2.34h2.86c0.74,0,1.09,0.52,1.09,1.07
		c0,0.52-0.37,1.06-1.09,1.06h-3.98c-0.68,0-1.19-0.46-1.19-1.15V17.7z" />
			<path fill="currentcolor" d="M151.11,17.47c0-0.77,0.49-1.2,1.16-1.2c0.29,0,0.77,0.23,0.97,0.51l4.89,6.53h0.03v-5.83
		c0-0.77,0.49-1.2,1.16-1.2c0.66,0,1.16,0.43,1.16,1.2v8.77c0,0.77-0.49,1.2-1.16,1.2c-0.29,0-0.75-0.23-0.97-0.51l-4.89-6.45h-0.03
		v5.76c0,0.77-0.49,1.2-1.16,1.2c-0.66,0-1.16-0.44-1.16-1.2V17.47z" />
			<path fill="currentcolor" d="M166,13.81l0.12,0.06c0.26,0.14,0.43,0.39,0.43,0.62c0,0.54-0.35,1.13-0.82,1.13c-0.31,0-0.17,0-0.62-0.15
		l-1.73-0.68c-0.43-0.16-0.63-0.46-0.63-0.91c0-0.42,0.37-0.96,0.83-0.96c0.17,0,0.35,0.06,0.58,0.16L166,13.81z M161.77,17.7
		c0-0.77,0.38-1.34,1.2-1.34h3.82c0.74,0,1.1,0.52,1.1,1.07c0,0.52-0.37,1.07-1.1,1.07h-2.71v2.25h2.53c0.76,0,1.13,0.53,1.13,1.07
		c0,0.52-0.38,1.07-1.13,1.07h-2.53v2.34h2.85c0.74,0,1.1,0.52,1.1,1.07c0,0.52-0.37,1.06-1.1,1.06h-3.98
		c-0.68,0-1.19-0.46-1.19-1.15V17.7z" />
			<path fill="currentcolor" d="M168.37,17.95c-0.08-0.2-0.11-0.35-0.11-0.57c0-0.65,0.57-1.11,1.19-1.11c0.52,0,0.85,0.34,1.05,0.75
		l2.39,6.42l2.39-6.42c0.2-0.42,0.52-0.75,1.05-0.75c0.62,0,1.19,0.46,1.19,1.11c0,0.22-0.03,0.37-0.11,0.57l-3.38,8.59
		c-0.2,0.49-0.46,0.89-1.14,0.89c-0.68,0-0.94-0.4-1.14-0.89L168.37,17.95z" />
			<path fill="currentcolor" d="M178.16,17.7c0-0.77,0.38-1.34,1.2-1.34h3.83c0.74,0,1.1,0.52,1.1,1.07c0,0.52-0.37,1.07-1.1,1.07h-2.72v2.25
		H183c0.75,0,1.13,0.53,1.13,1.07c0,0.52-0.39,1.07-1.13,1.07h-2.53v2.34h2.86c0.74,0,1.09,0.52,1.09,1.07
		c0,0.52-0.37,1.06-1.09,1.06h-3.98c-0.68,0-1.19-0.46-1.19-1.15V17.7z" />
		</g>
	</Svg>
);
